export class UserModel {
    isModerator: boolean = true;
    isAuditor: boolean = false;
    isTeamLeader: boolean;
    isAgent: boolean;
    Token: string;
    campaignId : number;
}

export class Users {
    id :number;
    user_role_id : number;
    username : string;
}