import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'members', loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule) },
  { path: 'team-leader', loadChildren: () => import('./modules/team-leader/team-leader.module').then(m => m.TeamLeaderModule) },
  { path: 'agent', loadChildren: () => import('./modules/agents/agents.module').then(m => m.AgentsModule) },
  { path: 'agent-call-center', loadChildren: () => import('./modules/agents-call-center/agents-call-center.module').then(m => m.AgentsCallCenterModule) },
  { path: '**', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
