import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserProvider } from '../../Providers/UserProvider';
import { RouteDataProvider } from '../../Shared/RouteDataProvider.provider';
import { WeekEntriesProvider } from '../../agents/Providers/WeekEntriesProvider';
import { WeekEnttries, PictureTaskModel } from '../../team-leader/Models/WeekEntries';
import { AgentProvider } from '../../agents/Providers/AgentProvider';
import { PosmAuditTransactionsModel } from '../../team-leader/Models/PosmAuditTransactionsModel';
import { TeamLeaderProvider } from '../../team-leader/Providers/TeamLeaderProvider';
import { MysteryShopperRecord } from '../../team-leader/Models/MysteryShopper';
import { InvoiceModel } from '../../agents/Models/Invoice';
import { NgZone } from '@angular/core';

declare var $;

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  userLoginRole: string;
  userName: string;
  isTL: boolean = false;
  isCallAgent : boolean = false;
  isAgent: boolean = false;
  currentRouter: string;
  currentRouerArea: string;
  currentRouterInside: string;
  currentRouterInsideArea: string;
  weekEntries: WeekEnttries[];
  invoices: InvoiceModel[];
  posm: PosmAuditTransactionsModel[];
  cooler: PosmAuditTransactionsModel[];
  engagementposm: PosmAuditTransactionsModel[];
  mysteryRecords: MysteryShopperRecord[];
  TL_weekEntries: WeekEnttries[];
  TL_invoices: InvoiceModel[];
  TL_posm: PosmAuditTransactionsModel[];
  TL_cooler: PosmAuditTransactionsModel[];
  TL_mystery: MysteryShopperRecord[];
  TL_enagement_posm: PosmAuditTransactionsModel[];
  TL_enagement_picturetask: any[];
  TL_enagements: any[];
  mystery: MysteryShopperRecord[];
  TL_engagementPictureRecords: PictureTaskModel[];
  engagementPictureRecords: PictureTaskModel[];
  constructor(public router: Router,
    public userProvider: UserProvider,
    public routerDataProviders: RouteDataProvider,
    public weekEntriesProviders: WeekEntriesProvider,
    public agentProviders: AgentProvider,
    public teamLeaderProvider: TeamLeaderProvider,
    public zone: NgZone) {

    this.routerDataProviders.subArea.subscribe(
      r => {
        this.currentRouter = r;
        console.log(`current router ${r}`)
      }
    )

    this.routerDataProviders.area.subscribe(
      r => {
        this.currentRouerArea = r;
      }
    )

    this.routerDataProviders.subAreaInside.subscribe(
      r => {
        this.currentRouterInside = r;
      }
    )

    this.routerDataProviders.subAreaInsideArea.subscribe(
      r => {
        this.currentRouterInsideArea = r
      }
    )

  }

  ngOnInit() {
    $(document).ready(() => {
      $('.sidebar-menu').tree();
    });
    this.userName = localStorage.getItem('userName');
    this.userLoginRole = localStorage.getItem('userRole');
    if (typeof this.userLoginRole != 'undefined' && this.userLoginRole != null) {
      switch (this.userLoginRole) {
        case '13':
          this.isCallAgent = true;
          break;
        case '8':
          this.isTL = true;
          break;
        case '9':
          this.isAgent = true;
      }
    }
  }

  // ngDoCheck() {

  //   this.weekEntries = this.teamLeaderProvider.weekEntries;
  //   this.invoices = this.agentProviders.invoiceRecords;
  //   this.posm = this.agentProviders.posmAuditTransaction;
  //   this.engagementposm = this.agentProviders.engagementposmAuditTransaction;
  //   this.cooler = this.agentProviders.coolerAuditTransaction;
  //   this.engagementPictureRecords = this.agentProviders.engagementPictureRecords;
  //   this.mystery = this.agentProviders.MysteryTransaction;
  //   this.TL_weekEntries = this.teamLeaderProvider.weekEntries;
  //   this.TL_invoices = this.teamLeaderProvider.invoiceRecords;
  //   this.TL_posm = this.teamLeaderProvider.posmAuditTransaction;
  //   this.TL_mystery = this.teamLeaderProvider.mysteryShopperRecords;
  //   this.TL_cooler = this.teamLeaderProvider.coolerAuditTransaction;
  //   this.TL_enagement_posm = this.teamLeaderProvider.engagementposmAuditTransaction;
  //   this.TL_enagements = this.teamLeaderProvider.totalEnagement;
  //   this.TL_engagementPictureRecords = this.teamLeaderProvider.pictureTaskRecords;

  //   if (typeof this.weekEntries == "undefined") {
  //     this.weekEntries = [];
  //   }
  //   if (typeof this.engagementPictureRecords == "undefined") {
  //     this.engagementPictureRecords = [];
  //   }
  //   if (typeof this.cooler == "undefined") {
  //     this.cooler = [];
  //   }
  //   if (typeof this.posm == "undefined") {
  //     this.posm = [];
  //   }
  //   if (typeof this.engagementposm == "undefined") {
  //     this.engagementposm = [];
  //   }
  //   if (typeof this.invoices == "undefined") {
  //     this.invoices = [];
  //   }
  //   if (typeof this.mystery == "undefined") {
  //     this.mystery = [];
  //   }
  //   if (typeof this.TL_weekEntries == "undefined") {
  //     this.TL_weekEntries = [];
  //   }
  //   if (typeof this.TL_invoices == "undefined") {
  //     this.TL_invoices = [];
  //   }
  //   if (typeof this.TL_posm == "undefined") {
  //     this.TL_posm = [];
  //   }

  //   if (typeof this.TL_cooler == "undefined") {
  //     this.TL_cooler = [];
  //   }

  //   if (typeof this.TL_mystery == "undefined") {
  //     this.TL_mystery = [];
  //   }

  //   if (typeof this.TL_enagement_posm == "undefined") {
  //     this.TL_enagement_posm = [];
  //   }

  //   if (typeof this.TL_enagement_picturetask == "undefined") {
  //     this.TL_enagement_picturetask = [];
  //   }

  //   if (typeof this.TL_enagements == "undefined") {
  //     this.TL_enagements = [];
  //   }
  // }

  moveToDashboard() {
    this.router.navigate(['/team-leader']);
  }

  posmReassign() {
    this.router.navigate(['/team-leader/reassign/posm-reassign']);
  }

  posmTask() {
    this.router.navigate(['/agent/posm-tasks']);
  }

  coolerTask() {
    this.router.navigate(['/agent/cooler-tasks']);
  }

  invoiceTask() {
    this.router.navigate(['/agent/invoice-tasks']);
  }

  searchTask() {
    this.router.navigate(['/agent/outlet-search']);
  }

  contestTask() {
    this.router.navigate(['/agent/contest-tasks']);
  }

  callposmTask() {
    this.router.navigate(['/agent-call-center/call-posm-tasks']);
  }

  callcoolerTask() {
    this.router.navigate(['/agent-call-center/call-cooler-tasks']);
  }

  callinvoiceTask() {
    this.router.navigate(['/agent-call-center/call-invoice-tasks']);
  }

  callsearchTask() {
    this.router.navigate(['/agent-call-center/call-outlet-search']);
  }

  callcontestTask() {
    this.router.navigate(['/agent-call-center/call-contest-tasks']);
  }

  DashBoardTL() {
    this.router.navigate(['/team-leader']);
  }

  posmDashBoard() {
    this.router.navigate(['/team-leader/category/posm']);
  }

  coolerDashBoard() {
    this.router.navigate(['/team-leader/category/cooler']);
  }

  invoiceDashBoard() {
    this.router.navigate(['/team-leader/category/invoice']);
  }

  contestDashBoard() {
    this.router.navigate(['/team-leader/category/contest']);
  }

  contentDashBoard() {
    this.router.navigate(['/team-leader/category/content']);
  }

  mysteryDashBoard() {
    this.router.navigate(['/team-leader/category/mystery1']);
  }

  mysteryTask() {
    this.router.navigate(['/agent/mystery-tasks1']);
  }

  callmysteryTask() {
    this.router.navigate(['/agent-call-center/call-mystery-tasks1']);
  }

  engagementPosmDashBoard() {
    this.router.navigate(['/team-leader/category/engagement/posm/dashboard']);
  }

  engagementPictureDashBoard() {
    this.router.navigate(['/team-leader/category/engagement/picture/dashboard']);
  }

  engagementPosmAgent() {
    this.router.navigate(['/agent/engagement-posm-tasks']);
  }

  engagementPictureAgent() {
    this.router.navigate(['/agent/engagement-picture-tasks']);
  }

  callengagementPosmAgent() {
    this.router.navigate(['/agent-call-center/call-engagement-posm-tasks']);
  }

  callengagementPictureAgent() {
    this.router.navigate(['/agent-call-center/call-engagement-picture-tasks']);
  }

  goNothing() {

  }

  ngDestory() {
    this.weekEntries = [];
    this.invoices = [];
    this.posm = [];
    this.engagementposm = [];
    this.cooler = [];
    this.engagementPictureRecords= [];
    this.mystery = [];
    this.TL_weekEntries = [];
    this.TL_invoices= [];
    this.TL_posm = [];
    this.TL_mystery = [];
    this.TL_cooler = [];
    this.TL_enagement_posm = [];
    this.TL_enagements = [];
    this.TL_engagementPictureRecords = [];
  }

}
