import { Injectable } from "@angular/core";
import {
  ProgramModel,
  CampaignModel,
  RegionModel,
  StateModel,
} from "../../models/ProgramModel";
import { ProgramDataService } from "../../Service/program-data.service";
import { BehaviorSubject } from "rxjs";
import { UserServiceService } from "../../Service/user-service.service";
import { UserModel, Users } from "../../models/UserModel";
import { AlertService } from "../../Providers/alertProvider";
import {
  imageModel,
  PosmAuditTransactionsModel,
  TaskModel,
} from "../../team-leader/Models/PosmAuditTransactionsModel";
import { AgentService } from "../Service/agent.service";
import {
  PosmConfigModel,
  UserDetailRecords,
  PointsLedger,
  RedemptionModel,
  PosmAuditModel,
} from "../Models/posm-config";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { InvoiceModel } from "../../team-leader/Models/Invoice";
import { MysteryShopperRecord } from "../../team-leader/Models/MysteryShopper";
import { PictureTaskModel } from "../../team-leader/Models/WeekEntries";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TeamLeaderService } from "../../team-leader/Service/team-leader.service";

@Injectable({
  providedIn: "root",
})
export class AgentProvider {
  roleList: any[];
  programList: ProgramModel[];
  selectedProgram: BehaviorSubject<string> = new BehaviorSubject("");
  posmAuditTransaction: PosmAuditTransactionsModel[];
  engagementposmAuditTransaction: PosmAuditTransactionsModel[];
  engagementPictureRecords: PictureTaskModel[];
  coolerAuditTransaction: PosmAuditTransactionsModel[];
  MysteryTransaction: MysteryShopperRecord[];
  posmConfigList: PosmConfigModel[];
  invoiceRecords: InvoiceModel[];
  UserRole: any[];
  CampaignList: CampaignModel[];
  templateImage: any[];
  userDetailedDatas: UserDetailRecords[];
  pointsLedger: PointsLedger[];
  redemption: RedemptionModel[];
  posmAuditType: PosmAuditModel[];

  coolerDropdown = [
    { id: 1, name: "Cooler is Pure & As Per Planogram!" },
    { id: 2, name: "Cooler is Pure (but not as per planogram)" },
    { id: 5, name: "Cooler impure but top 1/2 rows with CR/HG pure" },
    { id: 6, name: "Cooler Pure & top 1 row with CR/HG pure" },
    { id: 7, name: "Cooler Pure & top 2 row with CR/HG pure" },
    {
      id: 8,
      name:
        "Non ABI Cooler more than 40% ABI purity keeping top 1 rows with CR/HG pure",
    },
    {
      id: 9,
      name:
        "Non ABI Cooler more than 40% ABI purity keeping top 2 rows with CR/HG pure",
    },
    { id: 10, name: "Non ABI chiller more than 40% ABI purity" },
    { id: 11, name: "Non ABI chiller impure" },
  ];

  constructor(
    public programService: ProgramDataService,
    public userService: UserServiceService,
    public alertService: AlertService,
    public agentService: AgentService,
    private toastr: ToastrService,
    private router: Router,
    public ngxLoader: NgxUiLoaderService,
    public teamLeaderService: TeamLeaderService
  ) {}

  getAllProgamData(): void {
    this.programService.getAllPrograms().subscribe(
      (response: any) => {
        this.programList = response.data;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getTemplates() {
    this.agentService.pictureTempalte().subscribe(
      (response) => {
        this.templateImage = response;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getPosmAuditTypes() {
    this.agentService.posmAuditType().subscribe(
      (response) => {
        this.posmAuditType = response;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getAllCampaignData(): void {
    this.agentService.GetCampaigns().subscribe(
      (response: any) => {
        this.CampaignList = response.data;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getAgentPosmData(): void {
    this.agentService.AgentPosmAuditTransactions().subscribe(
      (response: any) => {
        this.posmAuditTransaction = response;

        this.coolerAuditTransaction = this.engagementposmAuditTransaction = this.posmAuditTransaction;
        // Assign String;
        if (this.posmAuditTransaction.length > 0) {
          this.posmAuditTransaction.forEach((p) => {
            if (p.internal_status == 0) {
              p.statusString = "No Action";
            } else if (p.internal_status == 2) {
              p.statusString = "Approved";
            } else if (p.internal_status == 3) {
              p.statusString = "Rejected";
            }
            if (p.agent_comments == null) {
              p.agent_comments = "--Not Added--";
            }
            if (p.agent_comments == null) {
              p.agent_comments = "--Not Added--";
            }
            if (p.ml_computation_flag == 0) {
              p.mlStatusString = "No";
            } else if (p.ml_computation_flag == 1) {
              p.mlStatusString = "Yes";
            } else {
              p.mlStatusString = "N/A";
            }

            p.images = [];

            p.isCooler = false;

            p.evaluated_purity_score = p.purity_score;

            if (p.agent_comments != null) {
              let data = this.coolerDropdown.find((c) =>
                c.name.match(p.agent_comments)
              );
              if (typeof data != "undefined") {
                p.comment_selected = this.coolerDropdown.find(
                  (c) => c.id === data.id
                ).id;
                console.log(data);
                console.log(p.comment_selected);
              } else {
                p.comment_selected = 0;
              }
            }
            if (p.image_url != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.image_url;
              p.images.push(imageData);
            }

            if (p.denominator_image != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.denominator_image;
              p.images.push(imageData);
            }

            if (p.numerator_image != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.numerator_image;
              p.images.push(imageData);
            }

            if (p.xray_image != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.xray_image;
              p.images.push(imageData);
            }

            if (
              parseInt(p.purity_score) >= 40 &&
              p.is_competitive_cooler == 1
            ) {
              p.cooler_selection = this.coolerDropdown.filter(
                (i) => i.id == 8 || i.id == 9 || i.id == 10
              );
              p.comment_selected = 10;
            } else if (
              parseInt(p.purity_score) < 40 &&
              p.is_competitive_cooler == 1
            ) {
              p.cooler_selection = this.coolerDropdown.filter(
                (i) => i.id == 11
              );
            } else if (
              parseInt(p.purity_score) < 90 &&
              p.is_competitive_cooler == 0
            ) {
              p.cooler_selection = this.coolerDropdown.filter((i) => i.id == 5);
            } else {
              p.cooler_selection = this.coolerDropdown.filter(
                (i) => i.id == 1 || i.id == 2 || i.id == 6 || i.id == 7
              );
            }

            if (p.posm_ml_output_image != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.posm_ml_output_image;
              p.images.push(imageData);
            }
          });
        }

        this.engagementposmAuditTransaction = this.engagementposmAuditTransaction.filter(
          (p) => p.posm_type == 2 && p.campaign_id != null && p.task_id != null
        );
        this.posmAuditTransaction = this.posmAuditTransaction.filter(
          (p) => p.posm_type == 2 && p.campaign_id == null && p.task_id == null
        );
        this.coolerAuditTransaction = this.coolerAuditTransaction.filter(
          (p) => p.posm_type == 3
        );

        this.coolerAuditTransaction.forEach((p) => {
          p.isCooler = true;
        });

        this.posmAuditTransaction.forEach((p) => {
          p.isCooler = false;
          p.comment_selected = 0;
        });

        this.engagementposmAuditTransaction.forEach((p) => {
          p.isCooler = false;
        });
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getAgentPictureData(): void {
    this.agentService.AgentPictureAuditTransactions().subscribe(
      (response: any) => {
        this.engagementPictureRecords = response;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getAgentMysteryData(): void {
    this.agentService.AgentMysteryAuditTransactions().subscribe(
      (response: any) => {
        this.MysteryTransaction = response;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getAllProgamCampaignPOSM(program_id, campaign_id): void {
    this.posmAuditTransaction = this.posmAuditTransaction.filter(
      (p) => p.program_id == program_id && p.campaign_id == campaign_id
    );
  }

  getAllProgamPOSM(program_id): void {
    this.posmAuditTransaction = this.posmAuditTransaction.filter(
      (p) => p.program_id == program_id
    );
  }

  getAllProgamCampaignCooler(program_id, campaign_id): void {
    this.posmAuditTransaction = this.coolerAuditTransaction.filter(
      (p) => p.program_id == program_id && p.campaign_id == campaign_id
    );
  }

  getAllProgamCooler(program_id): void {
    this.posmAuditTransaction = this.coolerAuditTransaction.filter(
      (p) => p.program_id == program_id
    );
  }

  getAllProgamMystery(program_id): void {
    this.MysteryTransaction = this.MysteryTransaction.filter(
      (p) => p.program_id == program_id
    );
  }

  getAllPosmConfigList(): void {
    this.agentService.PosmConfigList().subscribe(
      (response) => {
        this.posmConfigList = response;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  loader: boolean;
  disabledApproved: boolean = false;
  posmImageApprove(posmTransaction: PosmAuditTransactionsModel, catageory) {
    this.loader = true;
    this.disabledApproved = true;
    this.ngxLoader.startBackground();
    this.agentService.PosmApprove(posmTransaction).subscribe(
      (response) => {
        this.ngxLoader.stopBackground();
        this.getAgentPosmData();
        this.loader = false;
        this.disabledApproved = false;
        this.posmAuditTransaction = response;
        if (catageory == 1) {
          this.router.navigate(["/agent/posm-tasks"]);
          this.toastr.success("Posm Image Approved", "Success");
        } else if (catageory == 6) {
          this.router.navigate(["/agent/cooler-tasks"]);
          this.toastr.success("Cooler Image Approved", "Success");
        } else if (catageory == 7) {
          this.router.navigate(["/agent/engagement-posm-tasks"]);
          this.toastr.success("Posm Image Approved", "Success");
        }
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  mysteryImageApprove(mysteryRecords: MysteryShopperRecord) {
    this.agentService.MysteryApprove(mysteryRecords).subscribe(
      (response) => {
        this.MysteryTransaction = response;
        this.router.navigate(["/agent/posm-tasks"]);
        this.toastr.success("Posm Image Approved", "Success");
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  disabledApprovedImage: boolean = false;

  pictureImageApprove(mysteryRecords: any) {
    this.disabledApprovedImage = true;
    this.agentService.pictureApprove(mysteryRecords).subscribe(
      (response) => {
        this.getAgentPictureData();
        this.disabledApprovedImage = false;
        this.engagementPictureRecords = response;
        this.router.navigate(["/agent/engagement-picture-tasks"]);
        this.toastr.success("Image Approved", "Success");
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  disabledRejectedImage: boolean = false;
  pictureImagReject(mysteryRecords: any) {
    this.disabledRejectedImage = true;
    this.agentService.pictureReject(mysteryRecords).subscribe(
      (response) => {
        this.getAgentPictureData();
        this.disabledRejectedImage = false;
        this.engagementPictureRecords = response;
        this.router.navigate(["/agent/engagement-picture-tasks"]);
        this.toastr.success("Image Rejected", "Success");
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  mysteryImagReject(mysteryRecords: MysteryShopperRecord) {
    this.agentService.MysteryReject(mysteryRecords).subscribe(
      (response) => {
        this.MysteryTransaction = response;
        this.router.navigate(["/agent/posm-tasks"]);
        this.toastr.success("Posm Image Rejected", "Success");
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  disabledRejectedPosm: boolean = false;
  posmImageReject(posmAuditTransaction: PosmAuditTransactionsModel, catageory) {
    this.disabledRejectedPosm = true;
    this.loader = true;
    this.agentService.PosmReject(posmAuditTransaction).subscribe(
      (response) => {
        this.getAgentPosmData();
        this.loader = false;
        this.disabledRejectedPosm = false;
        this.posmAuditTransaction = response;
        if (catageory == 1) {
          this.router.navigate(["/agent/posm-tasks"]);
          this.toastr.success("Posm Image Approved", "Success");
        } else if (catageory == 6) {
          this.router.navigate(["/agent/cooler-tasks"]);
          this.toastr.success("Cooler Image Approved", "Success");
        } else if (catageory == 7) {
          this.router.navigate(["/agent/engagement-posm-tasks"]);
          this.toastr.success("Posm Image Approved", "Success");
        }
        this.toastr.success("Posm Image Rejected", "Success");
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getInvoicesRecords(): void {
    this.agentService.getInvoiceRecordsData().subscribe((response) => {
      if (response) {
        this.invoiceRecords = response;
        this.invoiceRecords = this.invoiceRecords.filter(
          (i) => i.internal_status == 2
        );
      }
    });
  }

  getAllRoles(): void {
    this.agentService.getUserRoles().subscribe((response) => {
      if (response) {
        this.UserRole = response;
        this.UserRole = this.UserRole;
      }
    });
  }

  regions: RegionModel[];
  state: StateModel[];
  channels: any[];

  getRegionList(): void {
    this.teamLeaderService
      .get(this.teamLeaderService.REGION)
      .subscribe((res: any) => {
        this.regions = res.data.region as RegionModel[];
        if (this.regions.length > 0) {
          this.regions = this.regions.filter((i) => i.year_2021 == 1);
        }
      });
  }

  getStateList(): void {
    this.teamLeaderService
      .get(this.teamLeaderService.STATE)
      .subscribe((res: any) => {
        this.state = res.data as StateModel[];
        this.state.forEach((s) => {
          s.id = s.state_code;
        });
      });
  }

  getAllChannels(): void {
    this.teamLeaderService.get(this.teamLeaderService.CHANNEL).subscribe(
      (res: any) => {
        this.channels = res.data.all_channels;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  posmElements: any[];

  getAllPosmElements(): void {
    this.teamLeaderService.get(this.teamLeaderService.POSM_ELEMENTS).subscribe(
      (res: any) => {
        this.posmElements = res.data.posm_elements;
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
