import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgentsCallCenterComponent } from './agents-call-center.component';
import { NotfoundComponent } from './Components/category/notfound/notfound.component';



const routes: Routes = [
  {
    path: '',
    component: AgentsCallCenterComponent,
    children: [
      {
        path: 'call-posm-tasks',
        loadChildren: () => import('../agents-call-center/Components/category/posm/posm-task/posm-task.module')
          .then(m => m.PosmTaskModule)
      },
      {
        path: 'call-cooler-tasks',
        loadChildren: () => import('../agents-call-center/Components/category/cooler/cooler-task/cooler-task.module')
          .then(m => m.CoolerTaskModule)
      },
      {
        path: 'call-mystery-tasks',
        loadChildren: () => import('../agents-call-center/Components/category/mystery/mystery-task/mystery-task.module')
          .then(m => m.MysteryTaskModule)
      },
      {
        path: 'call-invoice-tasks',
        loadChildren: () => import('../agents-call-center/Components/category/invoice/invoice-task/invoice-task.module')
          .then(m => m.InvoiceTaskModule)
      },
      {
        path: 'call-contest-tasks',
        loadChildren: () => import('../agents-call-center/Components/category/contest/contest-task/contest-task.module')
          .then(m => m.ContestTaskModule)
      },
      {
        path: 'call-engagement-posm-tasks',
        loadChildren: () => import('../agents-call-center/Components/category/engagements/engagement-posm-task/engagement-posm-task.module')
          .then(m => m.EngagementPosmTaskModule)
      },
      {
        path: 'call-engagement-picture-tasks',
        loadChildren: () => import('../agents-call-center/Components/category/engagements/engagement-picture-task/engagement-picture-task.module')
          .then(m => m.EnagementPictureTaskModule)
      },
      {
        path: 'call-outlet-search',
        loadChildren: () => import('../agents-call-center/Components/category/outlet/outlet-search/outlet-search.module')
          .then(m => m.OutletSearchModule)
      },
      { path: '**', component: NotfoundComponent }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AgentsRoutingModule { }
