import { Injectable } from '@angular/core';
import { ProgramModel, CampaignModel } from '../../models/ProgramModel';
import { ProgramDataService } from '../../Service/program-data.service';
import { BehaviorSubject } from 'rxjs';
import { UserServiceService } from '../../Service/user-service.service';
import { UserModel, Users } from '../../models/UserModel';
import { AlertService } from '../../Providers/alertProvider';
import { PosmAuditTransactionsModel } from '../../team-leader/Models/PosmAuditTransactionsModel';
import { AgentService } from '../Service/agent.service';
import { PosmConfigModel, UserDetailRecords, PointsLedger, RedemptionModel } from '../Models/posm-config';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { InvoiceModel } from '../../team-leader/Models/Invoice';
import { MysteryShopperRecord } from '../../team-leader/Models/MysteryShopper';
import { PictureTaskModel } from '../../team-leader/Models/WeekEntries';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class AgentCallCenterProvider {
  roleList: any[];
  programList: ProgramModel[];
  selectedProgram: BehaviorSubject<string> = new BehaviorSubject("");
  posmAuditTransaction: PosmAuditTransactionsModel[];
  engagementposmAuditTransaction: PosmAuditTransactionsModel[];
  engagementPictureRecords: PictureTaskModel[];
  coolerAuditTransaction: PosmAuditTransactionsModel[];
  MysteryTransaction: MysteryShopperRecord[];
  posmConfigList: PosmConfigModel[];
  invoiceRecords: InvoiceModel[];
  UserRole: any[];
  CampaignList: CampaignModel[];
  templateImage: any[];
  userDetailedDatas: UserDetailRecords[];
  pointsLedger: PointsLedger[];
  redemption: RedemptionModel[];

  constructor(public programService: ProgramDataService,
    public userService: UserServiceService,
    public alertService: AlertService,
    public agentService: AgentService,
    private toastr: ToastrService,
    private router: Router,
    public ngxLoader: NgxUiLoaderService) {
  }

  getAllProgamData(): void {
    this.programService.getAllPrograms()
      .subscribe(
        (response: any) => {
          this.programList = response.data;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  getTemplates() {
    this.agentService.pictureTempalte()
      .subscribe(
        (response) => {
          this.templateImage = response;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  getAllCampaignData(): void {
    this.agentService.GetCampaigns()
      .subscribe(
        (response: any) => {
          this.CampaignList = response.data;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  getAgentPosmData(): void {
    this.agentService.AgentPosmAuditTransactions()
      .subscribe(
        (response: any) => {
          this.posmAuditTransaction = response;
          this.coolerAuditTransaction = response;
          this.engagementposmAuditTransaction = response;
          this.engagementposmAuditTransaction = this.engagementposmAuditTransaction.filter(p => p.posm_type == 2
            && p.campaign_id != null && p.task_id != null);
          this.posmAuditTransaction = this.posmAuditTransaction.filter(p => p.posm_type == 2 && p.campaign_id == null && p.task_id == null);
          this.coolerAuditTransaction = this.coolerAuditTransaction.filter(p => p.posm_type == 3);
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  getAgentPictureData(): void {
    this.agentService.AgentPictureAuditTransactions()
      .subscribe(
        (response: any) => {
          this.engagementPictureRecords = response;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  getAgentMysteryData(): void {
    this.agentService.AgentMysteryAuditTransactions()
      .subscribe(
        (response: any) => {
          this.MysteryTransaction = response;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  getAllProgamCampaignPOSM(program_id, campaign_id): void {
    this.posmAuditTransaction = this.posmAuditTransaction.filter(p => p.program_id == program_id && p.campaign_id == campaign_id);
  }

  getAllProgamPOSM(program_id): void {
    this.posmAuditTransaction = this.posmAuditTransaction.filter(p => p.program_id == program_id);
  }

  getAllProgamCampaignCooler(program_id, campaign_id): void {
    this.posmAuditTransaction = this.coolerAuditTransaction.filter(p => p.program_id == program_id && p.campaign_id == campaign_id);
  }

  getAllProgamCooler(program_id): void {
    this.posmAuditTransaction = this.coolerAuditTransaction.filter(p => p.program_id == program_id);
  }



  getAllProgamMystery(program_id): void {
    this.MysteryTransaction = this.MysteryTransaction.filter(p => p.program_id == program_id);
  }

  getAllPosmConfigList(): void {
    this.agentService.PosmConfigList()
      .subscribe(
        (response) => {
          this.posmConfigList = response;
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  loader: boolean;

  posmImageApprove(posmTransaction: PosmAuditTransactionsModel, catageory) {
    this.loader = true;
    this.ngxLoader.startBackground();
    this.agentService.PosmApprove(posmTransaction)
      .subscribe(
        (response) => {
          this.ngxLoader.stopBackground();
          this.loader = false;
          this.posmAuditTransaction = response;
          if (catageory == 1) {
            this.router.navigate(['/agent/posm-tasks']);
            this.toastr.success("Posm Image Approved", "Success");
          }
          else if (catageory == 6) {
            this.router.navigate(['/agent/cooler-tasks']);
            this.toastr.success("Cooler Image Approved", "Success");
          }
          else if (catageory == 7) {
            this.router.navigate(['/agent/engagement-posm-tasks']);
            this.toastr.success("Posm Image Approved", "Success");
          }
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  mysteryImageApprove(mysteryRecords: MysteryShopperRecord) {
    this.agentService.MysteryApprove(mysteryRecords)
      .subscribe(
        (response) => {
          this.MysteryTransaction = response;
          this.router.navigate(['/agent/posm-tasks']);
          this.toastr.success("Posm Image Approved", "Success");
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  pictureImageApprove(mysteryRecords: PictureTaskModel) {
    this.agentService.pictureApprove(mysteryRecords)
      .subscribe(
        (response) => {
          this.engagementPictureRecords = response;
          this.router.navigate(['/agent/engagement-picture-tasks']);
          this.toastr.success("Image Approved", "Success");
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  pictureImagReject(mysteryRecords: PictureTaskModel) {
    this.agentService.pictureReject(mysteryRecords)
      .subscribe(
        (response) => {
          this.engagementPictureRecords = response;
          this.router.navigate(['/agent/engagement-picture-tasks']);
          this.toastr.success("Image Rejected", "Success");
        },
        error => {
          this.alertService.error(error);
        }
      )
  }


  mysteryImagReject(mysteryRecords: MysteryShopperRecord) {
    this.agentService.MysteryReject(mysteryRecords)
      .subscribe(
        (response) => {
          this.MysteryTransaction = response;
          this.router.navigate(['/agent/posm-tasks']);
          this.toastr.success("Posm Image Rejected", "Success");
        },
        error => {
          this.alertService.error(error);
        }
      )
  }



  posmImageReject(posmAuditTransaction: PosmAuditTransactionsModel) {
    this.loader = true;
    this.agentService.PosmReject(posmAuditTransaction)
      .subscribe(
        (response) => {
          this.loader = false;
          this.posmAuditTransaction = response;
          this.router.navigate(['/agent/posm-tasks']);
          this.toastr.success("Posm Image Rejected", "Success");
        },
        error => {
          this.alertService.error(error);
        }
      )
  }

  getInvoicesRecords(): void {
    this.agentService.getInvoiceRecordsData().subscribe(
      (response) => {
        if (response) {
          this.invoiceRecords = response;
          this.invoiceRecords = this.invoiceRecords.filter(i => i.internal_status == 2);
        }
      }
    )
  }

  getAllRoles(): void {
    this.agentService.getUserRoles().subscribe(
      (response) => {
        if (response) {
          this.UserRole = response;
          this.UserRole = this.UserRole;
        }
      }
    )
  }

  
}
