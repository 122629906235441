import { Component, OnInit } from '@angular/core';
declare var $;
import {Location} from '@angular/common';
@Component({
  selector: 'app-agents-call-center',
  templateUrl: './agents-call-center.component.html',
  styleUrls: ['./agents-call-center.component.scss']
})
export class AgentsCallCenterComponent implements OnInit {

  constructor(private _location: Location) {
    console.log('agent call center loaded');
   }

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
    $('body').addClass('hold-transition skin-blue sidebar-mini');
  }

  backClicked() {
    this._location.back();
  }

}
