
import { WeekEnttries, RatingModel, RatingParameter, Rewards, DashboardCountData, WeeksBasedEntriesData, WeekBasedTask, Category, City, Week } from './WeekEntries';
import { InvoiceModel } from './Invoice';

export const REWARDS: Rewards[] = [
  { 'id': 1, 'prod_name': 'Amazon Echo Dot', 'status': 1 },
  // { 'id': 2, 'name': 'Reward 2', 'status': 1 },
  // { 'id': 3, 'name': 'Reward 3', 'status': 1 },
  // { 'id': 4, 'name': 'Reward 4', 'status': 1 }
]

export const DASHBOARDCOUNT: DashboardCountData[] = [
  { 'user_id': 1, 'total_entries': 1010, 'shortlisted_entries': 239, 'confirmed_entries': 989, 'rejected_entries': 89, 'onhold_entries': 899, 'unopened_entries': 56 },
  { 'user_id': 12, 'total_entries': 1010, 'shortlisted_entries': 239, 'confirmed_entries': 989, 'rejected_entries': 89, 'onhold_entries': 899, 'unopened_entries': 56 },
  { 'user_id': 12, 'total_entries': 1010, 'shortlisted_entries': 239, 'confirmed_entries': 989, 'rejected_entries': 89, 'onhold_entries': 899, 'unopened_entries': 56 }
]


export const REWARDENTRIES: WeeksBasedEntriesData[] = [

  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 1,
    'user_id': 1
  },
  // {
  //   'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
  //   'total_rewards': '100',
  //   'confirmed_winners': '200',
  //   'pending_winners': '300',
  //   'overdue_rewards': '200',
  //   'reward_id': 1,
  //   'user_id': 1
  // },
  // {
  //   'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
  //   'total_rewards': '100',
  //   'confirmed_winners': '200',
  //   'pending_winners': '300',
  //   'overdue_rewards': '200',
  //   'reward_id': 1,
  //   'user_id': 1
  // },
  // {
  //   'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
  //   'total_rewards': '100',
  //   'confirmed_winners': '200',
  //   'pending_winners': '300',
  //   'overdue_rewards': '200',
  //   'reward_id': 1,
  //   'user_id': 1
  // },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 2,
    'user_id': 1
  },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 2,
    'user_id': 1
  },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 2,
    'user_id': 1
  },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 3,
    'user_id': 1
  },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 3,
    'user_id': 1
  },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 3,
    'user_id': 1
  },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 3,
    'user_id': 1
  },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 3,
    'user_id': 2
  },
  {
    'week': 'Week 1 (1st Sep 2019 - 7th Sep 2019)',
    'total_rewards': '100',
    'confirmed_winners': '200',
    'pending_winners': '300',
    'overdue_rewards': '200',
    'reward_id': 4,
    'user_id': 1
  },
]


export const WEEKTASK: WeekEnttries[] = [
//   {
//     "id": 787,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-09-25 16:06:40",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5d8b436b6ab65.png",
//     "submitted_date": "2019-09-25 16:08:55.060172",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Khaleel",
//     "city_id": 1107,
//     "cityname": "Bangalore ",
//     "description": "Testing ",
//     "title": "Khaleel Testing ",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 788,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-09-26 13:14:50",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5d8c6ee1aa6ce.png",
//     "submitted_date": "2019-09-27 15:50:35.5599",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "JAGDEV SINGH",
//     "city_id": 2147,
//     "cityname": "Ludhiana ",
//     "description": "Education and health play a biggest role in growth of a country and poorest child also have rights of education and equality.",
//     "title": "REDUCE INEQUALITIES",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 789,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-09-30 07:42:32",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5d9165aad2e29.png",
//     "submitted_date": "2019-10-01 13:22:01.611869",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Ravi Kant Soni",
//     "city_id": 5001,
//     "cityname": "others",
//     "description": "I started my collage from world's today's position which is fullfill with industry due to all kinds of smoke global warming and earth converting in drought and water level goes more down... We can change our climate again with more plantation and cleaning air with our possible affords",
//     "title": "CLIMATE ACTION",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 790,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-09-30 08:18:17",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5d916d2aa536d.png",
//     "submitted_date": "2019-10-01 13:22:10.160227",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Rajib Dutta",
//     "city_id": 2882,
//     "cityname": "Hyderabad",
//     "description": "The aim of the collage is to spread awareness to the mass to use clean energy such as energy from wind, Sun, Battery, Bio mass, Hydro etc. The energy generated from this sources is affordable and sustainable. It also helps environment to prevent from deadly gases like CO2, methane etc.",
//     "title": "Clean and Affordable Energy",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 791,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-09-30 21:54:46",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5d922ca2944fa.png",
//     "submitted_date": "2019-10-01 13:23:04.808885",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "DEREK MARCUS FEREIRA",
//     "city_id": 1894,
//     "cityname": "Thane ",
//     "description": "We are living on this planet as if we had another one to go.\nEarth provides enough to satisfy every mans needs, but not every mans greed.",
//     "title": "SAVE NATURE",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 792,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-05 17:34:08",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5d98870bc8748.png",
//     "submitted_date": "2019-10-06 13:05:25.265821",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "pradeep modi",
//     "city_id": 5001,
//     "cityname": "others",
//     "description": "MOST IMPORTANT FOR OUR LIFE AND OUR NEW GENERATION ",
//     "title": "CLIMATE ACTION ",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 793,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-06 19:20:24",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5d99f24350ab3.png",
//     "submitted_date": "2019-10-07 15:39:36.992206",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "PANNERSELVAM",
//     "city_id": 2517,
//     "cityname": "Tiruchirappalli ",
//     "description": null,
//     "title": "SUSTAINABLE CITIES AND COMMUNITIES",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 794,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-07 10:37:59",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5d9ac89d3e971.png",
//     "submitted_date": "2019-10-07 15:39:42.620641",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Yogesh Hardasani",
//     "city_id": 860,
//     "cityname": "Surat ",
//     "description": "This collage shows if females given equal opportunity can match man in every field.",
//     "title": "Gender Equality",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 795,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-10 23:52:55",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5d9f7733abeea.png",
//     "submitted_date": "2019-10-11 13:43:05.384654",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "MOHD ZAKARIYA",
//     "city_id": 694,
//     "cityname": "New Delhi",
//     "description": "This collage is all about the saving of ecosystem and conserving it from global warming and many other disasters by using eco- friendly things.",
//     "title": "Save Ecosystem Be Eco friendly",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 796,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-11 18:18:05",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5da07aba39a57.png",
//     "submitted_date": "2019-10-12 13:12:45.828217",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "RAKESH SHARMA",
//     "city_id": 2147,
//     "cityname": "Ludhiana ",
//     "description": null,
//     "title": "RAKESH SHARMA",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 797,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-12 08:28:39",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5da142068c31b.png",
//     "submitted_date": "2019-10-12 13:13:55.477515",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Nandini sharma",
//     "city_id": 5001,
//     "cityname": "others",
//     "description": "I have divided my collage in two sides , one side shows greenery ,fresh water , beautiful nature which represents life on the other side their is pollution, plastic ,drought which represents disaster .climate change is the biggest challenge for everyone and we must do something to protect our earth and future generation . ",
//     "title": "save earth . save yourself . save future ",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 798,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-12 18:58:13",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5da1d534a6ef5.png",
//     "submitted_date": "2019-10-14 12:18:47.489544",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "RAJENDRAN A",
//     "city_id": 1348,
//     "cityname": "Thrissur ",
//     "description": "WOMAN IS MORE CAPABLE THAN MEN, HENCE TREAT WOMAN CHILD WITH EQUALITY. WITHOUT WOMAN NO HUMANITY",
//     "title": "GIRL CHILD WITH EQUALITY",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 799,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-12 23:22:45",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5da213ea27f4a.png",
//     "submitted_date": "2019-10-14 12:21:44.123485",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Jenicaa Poovanna",
//     "city_id": 1107,
//     "cityname": "Bangalore ",
//     "description": "The day women and girls are treated with equality ,respected ,honoured and provided with quality education the world is going to be a better place to live in ;they are the foundations of a better society.",
//     "title": "Women Strong Nation Strong",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 800,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-13 17:25:54",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5da311070b274.png",
//     "submitted_date": "2019-10-14 12:23:36.491447",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Vinay Prabhu Mhambre",
//     "city_id": 5001,
//     "cityname": "others",
//     "description": "to live a fruitful life...Reduce Reuse Recycle so that we get a great place to live.... but that would only be possible if we respect every lady and every girl ad every child",
//     "title": "RRR - Respect the Girl Child",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 801,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-14 12:39:29",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5da41f6e40fd9.png",
//     "submitted_date": "2019-10-14 12:43:27.145342",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Prabhakar",
//     "city_id": 1107,
//     "cityname": "Bangalore ",
//     "description": null,
//     "title": "Test",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 802,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-17 13:23:52",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5da81e51c7bc1.png",
//     "submitted_date": "2019-10-17 16:32:40.211478",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Tauseef",
//     "city_id": 1107,
//     "cityname": "Bangalore ",
//     "description": "The underprivileged kids need quality education and we should help make this happen by doing whatever we can. ",
//     "title": "Quality education for the under privileged",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 803,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-17 13:36:26",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5da8217c85886.png",
//     "submitted_date": "2019-10-17 16:34:23.227382",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Pankaj kumar sahoo",
//     "city_id": 2007,
//     "cityname": "Bhubaneswar ",
//     "description": "In my collage i want to give message to our society that if we can self awareness then  we can save more and more like our earth from global warming by more tree plantation,waste of water. we can save many more poor live by giving extra food that we were through outside.We can keep clean  our environment by using proper dustbin.we should give attention to that poor people who were live their lives under open sky by the help of ordinary polythene house.",
//     "title": "CHANGES COMES FROM SELF AWARENESS",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 804,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-17 21:29:26",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5da8903048860.png",
//     "submitted_date": "2019-10-18 13:10:53.696564",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "G S Pandey",
//     "city_id": 2681,
//     "cityname": "Lucknow ",
//     "description": "Made and arrange by G S PANDEY and Tresandhya Pandey",
//     "title": "University of Lucknow",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 805,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-18 10:52:51",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5da94c7c8b0cb.png",
//     "submitted_date": "2019-10-18 13:11:10.508485",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "riddhima pandey",
//     "city_id": 5001,
//     "cityname": "others",
//     "description": "GENDER EQUALITY",
//     "title": "GENDER EQUALITY",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 806,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-18 11:04:12",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5da94f239c426.png",
//     "submitted_date": "2019-10-18 13:11:23.196153",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "siddhima pandey",
//     "city_id": 5001,
//     "cityname": "others",
//     "description": null,
//     "title": "AFFORDABLE AND CLEAN ENERGY",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 807,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-19 15:11:32",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5daade9d00f03.png",
//     "submitted_date": "2019-10-21 14:08:00.952953",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Vidhya.R",
//     "city_id": 5001,
//     "cityname": "others",
//     "description": "-> plant trees \n->reduce pollution\n->use eco friendly items\nWE ALL SHOULD JOIN TOGETHER TO SAVE and PROTECT OUR EARTH",
//     "title": "Climate Action \"IN OUR HAND\"",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 808,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-19 20:14:13",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5dab24911cc2e.png",
//     "submitted_date": "2019-10-21 14:09:52.814256",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Manthan Somvanshi",
//     "city_id": 694,
//     "cityname": "New Delhi",
//     "description": "My collage depicts Climate segment of the SDG. And with  rise of voices of the youth, like International  Children's Peace Prize(2019) recipient, Greta Thunberg, it tries to spread a message that Save our planet which is unique in this Universe or Multiverse(if it exists).",
//     "title": "How Dare You! Pollute the Planet you call Mother",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 809,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-20 13:11:10",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5dac110c363cb.png",
//     "submitted_date": "2019-10-21 14:11:03.672792",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Christopher Nesamony",
//     "city_id": 5001,
//     "cityname": "others",
//     "description": "QUALITY EDUCATION CHANGE THE WORLD THE BEST INVESTMENT FOR DEVELOPMENT.",
//     "title": "QUALITY EDUCATION CHANGE THE WORLD",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 810,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-21 00:17:44",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5dacac1d4177d.png",
//     "submitted_date": "2019-10-21 14:11:16.396692",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "David D'Lima",
//     "city_id": 1107,
//     "cityname": "Bangalore ",
//     "description": "High School Science Teaches You Enough To Change The World",
//     "title": "Quality Education Enables Youngsters to Change the World",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 811,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-21 08:40:57",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5dad2209d1805.png",
//     "submitted_date": "2019-10-21 14:12:32.960391",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Sachin Palewar",
//     "city_id": 1798,
//     "cityname": "Nagpur ",
//     "description": null,
//     "title": "Girl Power",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 812,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-21 16:53:59",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5dad96355c61d.png",
//     "submitted_date": "2019-10-22 13:34:54.145308",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Sagar Shah",
//     "city_id": 5001,
//     "cityname": "others",
//     "description": "Now-a-days, the conventional energy source in one of the crucial factor for environment point of view or an availability of source. The use of Green Energy is one of the major alternatives for conventional source. Also it's a clean energy and renewable energy source. It has no adverse effects on our environment. This collage also promots the use of E-vehicles instead of conventional fuel vehicles. The collage includes three major green energy sources, that is solar energy, wind energy and biofuels. At last, there is no Planet \"B\"",
//     "title": "ENERGY OF THE FUTURE",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 813,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-22 15:03:55",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5daecd3ff15e0.png",
//     "submitted_date": "2019-10-23 13:33:43.067682",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Tejas Raval",
//     "city_id": 1107,
//     "cityname": "Bangalore ",
//     "description": "True equality means holding everyone accountable in the same way, regardless of race, gender, faith, ethnicity - or political ideology. Man or Woman, all are same and have equal power to make this world a better place.\n",
//     "title": "Overcome Gender Bias - We are all Equal",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 814,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-23 11:57:50",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5dafff8c187e0.png",
//     "submitted_date": "2019-10-23 13:35:05.700664",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Jangam Indu",
//     "city_id": 405,
//     "cityname": "Visakhapatnam ",
//     "description": null,
//     "title": "Say \"No\" to Child Labour  \"YES\" to Education",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 815,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-23 14:52:43",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5db01c08bf1f3.png",
//     "submitted_date": "2019-10-24 13:28:33.51116",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Prathamesh Satish Joshi",
//     "city_id": 426,
//     "cityname": "Aurangabad",
//     "description": "As the climate warms, it changes the nature of global rainfall, evaporation, snow, stream flow and other factors that affect water supply and quality. Specific impacts include: Warmer water temperatures affect water quality and accelerate water pollution.",
//     "title": "Global Warming & Solutions ",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 816,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-24 16:29:56",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5db18a0664f9e.png",
//     "submitted_date": "2019-10-25 17:50:47.024621",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "NIHITH",
//     "city_id": 405,
//     "cityname": "Visakhapatnam ",
//     "description": null,
//     "title": "Children are not ment to work, they are ment to study and flourish...",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 817,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-25 07:28:45",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5db2570085331.png",
//     "submitted_date": "2019-10-25 17:51:00.409188",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "Apoorv singhal",
//     "city_id": 694,
//     "cityname": "New Delhi",
//     "description": "Women and girls comprise half of the planet’s population; their empowerment is essential in expanding economic growth and promoting social development in a sustainable way.",
//     "title": "GENDER EQUALITY",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 818,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-25 21:18:15",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5db3197d7eba2.png",
//     "submitted_date": "2019-10-26 14:17:06.892984",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "MANDAR K RAUT",
//     "city_id": 1793,
//     "cityname": "Mumbai ",
//     "description": null,
//     "title": "CHOOSE A HALF BETWEEN TWO PARTS OF YOUR LIFE",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 819,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-26 12:30:35",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5db3ef9e048c8.png",
//     "submitted_date": "2019-10-26 14:17:38.930587",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "S KUNAL",
//     "city_id": null,
//     "cityname": "others",
//     "description": "Save Environment\nSave Earth\nSave Tree\nClean Earth",
//     "title": "Save environment",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 820,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-26 12:34:29",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5db3f02b5076a.png",
//     "submitted_date": "2019-10-26 14:17:51.582385",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "S KUNAL",
//     "city_id": 5001,
//     "cityname": "others",
//     "description": "Girl Boy Equality\nSave Girl\nEducation for everyone\nUpcoming generation\n",
//     "title": "Save Girl",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
// {
//     "id": 821,
//     "user_id": 12,
//     "username": "moderator",
//     "assigned_on": "2020-02-18 12:16:21.774549",
//     "reward": "",
//     "winningtime": "2019-10-26 13:04:00",
//     "points": 0,
//     "data_path": "http://api.hpcontest.in/uploads/collages/5db3f9e15db15.png",
//     "submitted_date": "2019-10-26 14:18:03.608616",
//     "moderator_internal_status": 0,
//     "previous_moderator_internal_status": 3,
//     "status": 0,
//     "customername": "akkineni raja shekhar",
//     "city_id": 2882,
//     "cityname": "Hyderabad",
//     "description": null,
//     "title": "Global Warming-Fix This",
//     "rank": 0,
//     "rating": [],
//     "comments": []
// },
]



export const CITY: City[] = [
  { 'id': 1, 'name': 'Banglore' },
  { 'id': 2, 'name': 'Belgam' },
  { 'id': 3, 'name': 'Mysore' },
  { 'id': 4, 'name': 'Mandya' },
  { 'id': 5, 'name': 'Tumkur' },
];

export const CATEGORY: Category[] = [
  { 'id': 1, 'name': 'Category 1' },
  { 'id': 2, 'name': 'Category 2' },
  { 'id': 3, 'name': 'Category 3' },
  { 'id': 4, 'name': 'Category 4' }
]

export const WEEK: Week[] = [
  { 'id': 1, 'name': 'Week 1' },
  { 'id': 2, 'name': 'Week 2' },
  { 'id': 3, 'name': 'Week 3' },
  { 'id': 4, 'name': 'Week 4' },
  { 'id': 5, 'name': 'Week 5' },
]

export const POSITION: Position[] = [
  
]

export const RATING: RatingModel[] = [
  { 'id': 1, 'rating': 0, 'contact': 'Creativity' },
  { 'id': 2, 'rating': 0, 'contact': 'Sustainability' },
  { 'id': 3, 'rating': 0, 'contact': 'Usage of Colours' },
  { 'id': 4, 'rating': 0, 'contact': 'Photography' },
  { 'id': 5, 'rating': 0, 'contact': 'Nature Photography' },
  { 'id': 6, 'rating': 0, 'contact': 'Nature Photography' },
  { 'id': 7, 'rating': 0, 'contact': 'Nature Photography' }
]

// export const RATINGPARAMETER: RatingParameter[] = [
//   { 'id': 1, 'name': 'Creativity', 'rating': 0 },
//   { 'id': 2, 'name': 'Sustainability', 'rating': 0 },
//   { 'id': 3, 'name': 'Usage of Colours', 'rating': 0 },
//   { 'id': 4, 'name': 'Photography', 'rating': 0 },
//   { 'id': 5, 'name': 'Nature Photography', 'rating': 0 },
//   { 'id': 6, 'name': 'Nature of Phography', 'rating': 0 }
// ] 

export const INOVICE: InvoiceModel[] = [];

// export const INOVICE: InvoiceModel[] = [
//   {
//     "record_id": 7,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-18 18:32:48.298655",
//     "customername": "Amit Kumar Jain",
//     "mobile": "9999609710",
//     "email": "somit819@gmail.com",
//     "booking_date": "2019-09-02 22:12:47",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d6e01a59c493.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 14:46:51.894821",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 19,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-19 11:42:27.800615",
//     "customername": "ANURAG HIMATSINGKA",
//     "mobile": "8210781272",
//     "email": "anurag_dumka@rediffmail.com",
//     "booking_date": "2019-09-03 11:23:02",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d6e34f262ac8.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 14:48:45.273823",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 28,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-19 11:48:20.829062",
//     "customername": "Amit",
//     "mobile": "9910892207",
//     "email": "krs.amitkumar@gmail.com",
//     "booking_date": "2019-09-03 12:31:34",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d6e101aaa21f.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 14:45:40.70429",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 29,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-19 12:24:48.129873",
//     "customername": "Sathish",
//     "mobile": "9940424568",
//     "email": "sash2371@gmail.com",
//     "booking_date": "2019-09-03 12:57:01",
//     "reason": 3,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d6e175ee2fe6.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Aug Dated",
//     "agent_comments_date": "2019-09-23 15:50:01.515914",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 36,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-19 13:11:47.150091",
//     "customername": "Vikash soni",
//     "mobile": "9977700389",
//     "email": "sonivikash95@gmail.com",
//     "booking_date": "2019-09-03 15:26:34",
//     "reason": 3,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d6e3a16d315b.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Invalid Bill",
//     "agent_comments_date": "2019-09-23 15:51:01.171975",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 43,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-19 14:24:54.311157",
//     "customername": "RAMESH",
//     "mobile": "9136778773",
//     "email": "RAMESHPATEL307@YAHOO.COM",
//     "booking_date": "2019-09-03 17:07:50",
//     "reason": 3,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d6e52a195e93.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Invalid Bill",
//     "agent_comments_date": "2019-09-23 15:51:13.316614",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 50,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-19 14:24:54.311157",
//     "customername": "Ruchika",
//     "mobile": "9779728860",
//     "email": "ruchikamantrao@gmail.com",
//     "booking_date": "2019-09-04 11:41:31",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d6f55e653ae2.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "1397",
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 15:51:29.41197",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 53,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-19 14:24:54.311157",
//     "customername": "Hamid Husain",
//     "mobile": "7587139421",
//     "email": "gethamid@gmail.com",
//     "booking_date": "2019-09-04 15:32:19",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d6f8dbfa4a62.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 15:51:53.054113",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 56,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Ritusudan Khatri",
//     "mobile": "9923180156",
//     "email": "ritusudankhatri@rediffmail.com",
//     "booking_date": "2019-09-04 16:13:22",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d6f9cf71816c.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 15:53:55.793592",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 59,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "M RAMANI",
//     "mobile": "9444053516",
//     "email": "mramani64@gmail.com",
//     "booking_date": "2019-09-04 16:43:01",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d6f9c66a7a78.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "1261",
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 15:53:27.113731",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 60,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Suryansh Sinha",
//     "mobile": "6362826245",
//     "email": "cbipin.bk@gmail.com",
//     "booking_date": "2019-09-04 17:30:00",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d6faf36ee00f.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 15:55:08.596888",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 63,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Divyan",
//     "mobile": "9943028899",
//     "email": "cutedivyan@gmail.com",
//     "booking_date": "2019-09-05 12:09:49",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d70aee7bc769.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 16:16:24.723063",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 66,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Pradeep Kumar Thottahtil",
//     "mobile": "9886674676",
//     "email": "emaildeepu@gmail.com",
//     "booking_date": "2019-09-05 12:54:52",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d70bbb862cb2.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 16:16:40.580221",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 67,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "VARJANGBHAI MARU",
//     "mobile": "9327234641",
//     "email": "grenterprise29@yahoo.com",
//     "booking_date": "2019-09-05 13:29:28",
//     "reason": 3,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d70c1a4e41a3.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 15:57:28.662609",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 70,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Karan kumar ",
//     "mobile": "9988668253",
//     "email": "Karankanojia10@gmail.com",
//     "booking_date": "2019-09-05 13:58:45",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d70c7fade604.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "400",
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:17:00.386792",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 71,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "DEVENDER KUMAR",
//     "mobile": "8219247503",
//     "email": "DEVENDER.OGLI1@GMAIL.COM",
//     "booking_date": "2019-09-05 14:29:03",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d70d07402b12.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 15:55:54.114492",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 72,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "DEVENDER",
//     "mobile": "9418499939",
//     "email": "DEVENDER_HIMRAL@HOTMAIL.COM",
//     "booking_date": "2019-09-05 14:49:19",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d70d3d8e5c06.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 16:17:45.481671",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 73,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "SEEMA KHOWAL",
//     "mobile": "8130484844",
//     "email": "seema.khowal@gmail.com",
//     "booking_date": "2019-09-05 14:51:22",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d70d89fbd4ab.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "2019-23",
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:18:09.019641",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 75,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "S KUNAL",
//     "mobile": "7982274605",
//     "email": "kunal.singhisking@yahoo.co.in",
//     "booking_date": "2019-09-05 15:08:49",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d70d98186743.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "3545",
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:18:45.850571",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 80,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Anju Kumari",
//     "mobile": "8210271008",
//     "email": "riyancy436@gmail.com",
//     "booking_date": "2019-09-05 16:50:31",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d70f0054bade.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 16:19:07.643481",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 83,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Rony Rodrigues",
//     "mobile": "8459427395",
//     "email": "rochrodrigue1800@gmail.com",
//     "booking_date": "2019-09-05 20:55:58",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d71298c03721.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "6937",
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:19:25.011318",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 90,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Vicky Jaiswal",
//     "mobile": "9920383104",
//     "email": "rock19218@gmail.com",
//     "booking_date": "2019-09-06 14:11:53",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7220bbe3a54.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "0355",
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:19:46.423856",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 91,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "RESHMA",
//     "mobile": "8879725755",
//     "email": "rrvaiude1910@gmail.com",
//     "booking_date": "2019-09-06 14:33:52",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7223700d194.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "2015-38",
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:21:57.424341",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 92,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Nilay Gupta ",
//     "mobile": "9867643103",
//     "email": "nilaygupta85@gmail.com",
//     "booking_date": "2019-09-06 14:34:58",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d725440a65df.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "6324",
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:26:49.961902",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 97,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Devendra yadav",
//     "mobile": "9029607884",
//     "email": "devendayadav480@gmail.com",
//     "booking_date": "2019-09-06 15:08:00",
//     "reason": 3,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7229d1f0ee8.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Duplicate bill",
//     "agent_comments_date": "2019-09-23 16:30:11.190161",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 101,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "AMRESH KUMAR ",
//     "mobile": "9892826011",
//     "email": "amresh.krkamat@gmail.com",
//     "booking_date": "2019-09-06 15:33:11",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d722f7d37622.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "3018",
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:32:49.312977",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 102,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Ramesh",
//     "mobile": "8123747775",
//     "email": "Rameshsohanlal1996@gmail.com",
//     "booking_date": "2019-09-06 16:25:10",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d723c3c3c069.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "257",
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bangalore",
//     "agent_comments_date": "2019-09-23 14:39:34.090615",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 106,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Volney Fernanades",
//     "mobile": "7057295853",
//     "email": "volneyfernandes7@gmail.com",
//     "booking_date": "2019-09-06 17:05:13",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d72453f51091.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "6909",
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 14:52:41.888971",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 111,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Priyanshi",
//     "mobile": "9560678610",
//     "email": "Kamlapadeliya4645@gmail.com",
//     "booking_date": "2019-09-06 23:06:04",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d72998e196f7.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "134-95",
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:33:14.252571",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 114,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "vivek jain",
//     "mobile": "9300115366",
//     "email": "jbp_trade@rediffmail.com",
//     "booking_date": "2019-09-07 12:57:57",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d735ce78c7fb.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 16:37:23.087627",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 116,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Deepak Sen ",
//     "mobile": "7987938086",
//     "email": "Deepak.sen@gmail.com",
//     "booking_date": "2019-09-07 16:11:24",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7389c6916a7.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear,\n\nHp cartridge not available ",
//     "agent_comments_date": "2019-09-23 16:38:29.735052",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 117,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "PARESHBHAI SOMAIYA",
//     "mobile": "9998215103",
//     "email": "psomaiya@yahoo.com",
//     "booking_date": "2019-09-07 17:40:45",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d739e9b0922b.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 16:38:54.459666",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 119,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Neeraj anand",
//     "mobile": "9416378260",
//     "email": "Anandambala@gmail.com",
//     "booking_date": "2019-09-07 18:06:33",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d73a489b4bf6.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 16:39:19.666349",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 121,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "partha sarathi dey",
//     "mobile": "9832741811",
//     "email": "deykand@gmail.com",
//     "booking_date": "2019-09-08 11:03:14",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7495670d382.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear, July Dated",
//     "agent_comments_date": "2019-09-23 16:39:50.341875",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 124,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Gaurav Saxena",
//     "mobile": "9910012299",
//     "email": "gauravsaxena79@gmail.com",
//     "booking_date": "2019-09-09 08:53:28",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d75c8d658b3e.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 16:40:06.5371",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 127,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "MANISH KUMAR GUPTA",
//     "mobile": "9958869232",
//     "email": "manishkr.gupta1989@gmail.com",
//     "booking_date": "2019-09-09 23:00:58",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d768cbb4e163.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "26-2013",
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:40:34.501087",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 134,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Rinkoo ",
//     "mobile": "9654611062",
//     "email": "chauhan.rinkoo1989@gmail.com",
//     "booking_date": "2019-09-10 18:17:59",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d779c15c6383.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 16:40:50.081607",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 136,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Valentinho Fernandes",
//     "mobile": "8888119657",
//     "email": "fernandesvalentinho89@gmail.com",
//     "booking_date": "2019-09-10 20:43:42",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d77c1af2d36c.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "7125",
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:41:27.195649",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 140,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Mukesh  Singh ",
//     "mobile": "9422115003",
//     "email": "singh_md1982@yahoo.com",
//     "booking_date": "2019-09-11 12:58:44",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d78a45374b9c.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "4265",
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:41:43.703266",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 141,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Munavar",
//     "mobile": "9879722742",
//     "email": "munavvarsaiyed@gmail.com",
//     "booking_date": "2019-09-11 13:40:27",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d78ad08668f6.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 16:42:13.425693",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 148,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Prabhakaran Jayaraman Masani",
//     "mobile": "8754407644",
//     "email": "prabhakaran_jm@hotmail.com",
//     "booking_date": "2019-09-12 09:57:14",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d79cbff4c70b.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 16:42:33.272739",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 151,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Rishabh",
//     "mobile": "9780025152",
//     "email": "astir04@gmail.com",
//     "booking_date": "2019-09-12 12:18:52",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d79f6593f61d.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "5595",
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:34:33.062546",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 159,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "kiran manodaar",
//     "mobile": "9167785463",
//     "email": "kiranmanodara1996@gmail.com",
//     "booking_date": "2019-09-12 16:15:59",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7a2201a978a.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "CP-5881",
//     "store_name": null,
//     "statename": "Mumbai ",
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:00:11.596625",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 160,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Venkat S",
//     "mobile": "9900213198",
//     "email": "venkat.srinivasa@gmail.com",
//     "booking_date": "2019-09-12 17:11:37",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7a3487214e0.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": "Bangalore ",
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 16:43:18.532671",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 164,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "RAJESH GUPTA",
//     "mobile": "9777644124",
//     "email": "akhilagrawal3103@gmail.com",
//     "booking_date": "2019-09-12 17:54:42",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7a393daee1c.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "00005",
//     "store_name": null,
//     "statename": "Raipur ",
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:32:05.608995",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 168,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "pavan penumetcha",
//     "mobile": "9886290029",
//     "email": "mailspcg@gmail.com",
//     "booking_date": "2019-09-12 18:14:05",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7a3dd99c72d.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "34771",
//     "store_name": null,
//     "statename": "Hyderabad",
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:01:03.381687",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 175,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "sathyendra m g",
//     "mobile": "9880847562",
//     "email": "mgsathyendra@gmail.com",
//     "booking_date": "2019-09-12 18:46:02",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7a47b9851bd.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": "Bangalore ",
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 16:43:46.539175",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 182,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Arvinderpal Singh",
//     "mobile": "9314959142",
//     "email": "asjaipuri@gmail.com",
//     "booking_date": "2019-09-12 23:17:47",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7a88488869b.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": "Jaipur ",
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 15:58:04.113525",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 184,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Vinod Pande",
//     "mobile": "9810817543",
//     "email": "vinodpande@gmail.com",
//     "booking_date": "2019-09-13 06:28:50",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7aea711ae70.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "34916",
//     "store_name": null,
//     "statename": "Gurgaon ",
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:01:25.526527",
//     "internal_status": 2,
//     "status": "Approved"
// },
// {
//     "record_id": 186,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Lakshya Bhatnagar",
//     "mobile": "9599938820",
//     "email": "lakshaybhatnagar99@gmail.com",
//     "booking_date": "2019-09-13 10:31:13",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7b242089fa5.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": null,
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 16:44:03.720604",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 187,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Sachin Palewar",
//     "mobile": "9823134596",
//     "email": "sachin@palewar.com",
//     "booking_date": "2019-09-13 11:23:41",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7b30851f0ff.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": "Nagpur ",
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 16:44:21.979598",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 189,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Ashwanth Manohar",
//     "mobile": "9362125859",
//     "email": "achoo555@gmail.com",
//     "booking_date": "2019-09-13 12:10:45",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7b3c017cbeb.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": "others",
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 16:44:34.515313",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 191,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "sunil",
//     "mobile": "7999514370",
//     "email": "katnisunil093@gmail.com",
//     "booking_date": "2019-09-13 14:52:29",
//     "reason": 3,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7b60b081d56.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": "others",
//     "qty": null,
//     "agent_comments": "May Dated, BIll not clear",
//     "agent_comments_date": "2019-09-23 16:45:03.302771",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 193,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "praveenkumar  nagarabett",
//     "mobile": "9880877779",
//     "email": "nagarabett@gmail.com",
//     "booking_date": "2019-09-13 15:43:59",
//     "reason": 2,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7b6dab97b84.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": null,
//     "store_name": null,
//     "statename": "Bangalore ",
//     "qty": null,
//     "agent_comments": "Bill not clear",
//     "agent_comments_date": "2019-09-23 16:45:15.715102",
//     "internal_status": 3,
//     "status": "Rejected"
// },
// {
//     "record_id": 195,
//     "user_id": 7,
//     "username": "agent1",
//     "assigned_on": "2019-09-20 16:59:03.757968",
//     "customername": "Neha Chawla",
//     "mobile": "9999835833",
//     "email": "nehafashion2011@gmail.com",
//     "booking_date": "2019-09-13 16:54:16",
//     "reason": null,
//     "bill_image": "http://api.hpcontest.in/uploads/bills/5d7b7ee3cf115.png",
//     "billsnap_comments": "Product Not Matched",
//     "invoice_no": "300000335420",
//     "store_name": null,
//     "statename": "New Delhi",
//     "qty": null,
//     "agent_comments": "Approved",
//     "agent_comments_date": "2019-09-23 16:36:35.4798",
//     "internal_status": 2,
//     "status": "Approved"
// }
// ]