import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserProvider } from '../../Providers/UserProvider';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  userName: string;
  userLoginRole: string;
  isTL: boolean;
  isAgent: boolean;

  constructor(private router: Router, private userProvider: UserProvider) {
  }

  ngOnInit() {
    this.userName = localStorage.getItem('userName');

    this.userLoginRole = localStorage.getItem('userRole');
    if (typeof this.userLoginRole != 'undefined' && this.userLoginRole != null) {
      switch (this.userLoginRole) {
        case '50':
          this.isTL = true;
          break;
        case '8':
          this.isTL = true;
          break;
        case '9':
          this.isAgent = true;
      }
    }
  }

  signOut() {
    localStorage.clear();
    this.router.navigate(['/']);
    this.userProvider.loading = false;
  }


  goToDashboard() {
      if(this.isAgent) {
        this.router.navigate(['/agent/posm-tasks']);
      }
      else if(this.isTL) {
        this.router.navigate(['/team-leader']);
      }
  }



}
