import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { TeamLeaderComponent } from './team-leader.component';
import { NotfoundComponent } from './Components/notfound/notfound.component';

const routes: Routes = [
  {
    path: '',
    component: TeamLeaderComponent,
    data: {
      breadcrumb: 'Team Leader'
    },
    children: [
      {
        path: '',
        component: DashboardComponent,
        data: {
          breadcrumb: 'Dashboard'
        },
      },
      {
        path: 'category',
        children: [
          {
            path: 'posm',
            loadChildren: () => import('./Components/category/posm/posm.module').then(p => p.PosmModule)
          },
          {
            path: 'cooler',
            loadChildren: () => import('./Components/category/cooler/cooler.module').then(p => p.CoolerModule)
          },
          {
            path: 'contest',
            loadChildren: () => import('./Components/category/contest/contest.module').then(p => p.ContestModule)
          },
          {
            path: 'invoice',
            loadChildren: () => import('./Components/category/invoice/invoice.module').then(p => p.InvoiceModule)
          },
          {
            path: 'mystery',
            loadChildren: () => import('./Components/category/mystery-shopper/mystery-shopper.module').then(m => m.MysteryShopperModule)
          },
          {
            path: 'engagement',
            loadChildren: () => import('./Components/category/engagement/engagement.module').then(m => m.EnagagementModule)
          }
        ]
      },
      {
        path: 'reassign',
        data: {
          breadcrumb: 'Reassign'
        },
        children: [
          {
            path: '',
            loadChildren: () => import('./Components/category/Reassign/reassign.module').then(r => r.ReassignModule)
          }
        ]
      }
    ]
  },
  { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TeamLeaderRoutingModule {

 }
