import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, map } from 'rxjs/operators';
import { GlobalService } from './SiteAuth/global.service';
import { ProgramModel } from '../models/ProgramModel';
import { Observable } from 'rxjs';

@Injectable()
export class ProgramDataService {

  constructor(
    private globalService: GlobalService,
    private http: HttpClient
  ) {
  }


  private getHeaders() {

    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    });
  }

  public static getChannelTypes(): Array<any> {
    return [
      {
        label: 'Select channel',
        value: ''
      },
      {
        label: 'Web App',
        value: 'web'
      },
      {
        label: 'Mobile App',
        value: 'mobileapp'
      },
      {
        label: 'SMS',
        value: 'sms'
      },
      {
        label: 'Missed Call',
        value: 'missedcall'
      }
    ];
  }

  public static getCategoryTypes(): Array<any> {
    return [
      {
        label: 'Select category',
        value: ''
      },
      {
        label: 'Category1',
        value: '1'
      },
      {
        label: 'Category2',
        value: '2'
      },
      {
        label: 'Category3',
        value: '3'
      },
      {
        label: 'Category4',
        value: '4'
      }
    ];
  }

  public static getLanguageTypes(): Array<any> {
    return [
      {
        label: 'Select languages',
        value: ''
      },
      {
        label: 'English',
        value: 'en'
      },
      {
        label: 'Hindi',
        value: 'hi'
      },
      {
        label: 'Bangla',
        value: 'bn'
      },
      {
        label: 'Telugu',
        value: 'te'
      }
    ];
  }

  public static getDayTypes(): Array<any> {
    return [
      {
        label: 'Select day',
        value: ''
      },
      {
        label: 'Sunday',
        value: 'sun'
      },
      {
        label: 'Monday',
        value: 'mon'
      },
      {
        label: 'Tuesday',
        value: 'tue'
      },
      {
        label: 'Wednesday',
        value: 'wed'
      },
      {
        label: 'Thursday',
        value: 'thu'
      },
      {
        label: 'Friday',
        value: 'fri'
      },
      {
        label: 'Saturday',
        value: 'sat'
      }
    ];
  }

  public static getCurrencyTypes(): Array<any> {
    return [
      {
        label: 'Select currency',
        value: ''
      },
      {
        label: 'Indian Ruppee',
        value: 'INR'
      },
      {
        label: 'United States Dollar',
        value: 'USD'
      }
    ];
  }

  // GET /v1/admin
  getAllPrograms(): Observable<ProgramModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.globalService.apiHostUrl + '/admin/program';
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <any>response;
          }
        ),
        catchError(this.handleError)
      )
  }

  

  private handleError(response: any) {

    let errorMessage: any = {};
    // Connection error
    if (response.error.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: 'Sorry, there was a connection error occurred. Please try again.'
      };
    } else {
      errorMessage = response.error;
    }

    return throwError(errorMessage);
  }


}
