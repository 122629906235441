import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { GlobalService } from "../../Service/SiteAuth/global.service";
import { PosmAuditTransactionsModel } from "../Models/PosmAuditTransactionsModel";
import { AssignedUsersCount } from "../Models/PosmAuditAssignedRecords";
import { WEEKTASK, INOVICE } from "../Models/mock-entries";
import { ProgramModel, CampaignModel } from "../../models/ProgramModel";
import { WeekEnttries } from "../../agents/Models/WeekEntries";
import { MysteryShopperRecord } from "../Models/MysteryShopper";
import {
  PosmConfigModel,
  PosmAuditModel,
} from "../../agents/Models/posm-config";
import { PictureTaskModel } from "../Models/WeekEntries";

//Related API's

const LOGIN_API = "/staff/getallposms";
const POSM_LIST = '/staff/get-posm-transactions';
const POSM_ASSIGNED_API = "/staff/posmbulkassignlist";
const PICTURE_ASSIGNED_API = "/staff/pitcurebulkassignlist";
const COOLER_ASSIGNED_API = "/staff/posmcoolerbulkassignlist";
const POSM_ENGAGEMENT_ASSIGNED_API = "/staff/posmengagementbulkassignlist";
const BULK_ASSIGNED_API = "/staff/bulkassign";
const REASSIGNED_API = "/staff/reassigntoagent";
const PROGAM_API = "/admin/program";
const CAMPAIGN_API = "/admin/campaigns";
const CONTEST_ASSIGNED_API = "/staff/contestbulkassignlist";
const MYSTERY_ASSIGNED_API = "/staff/mysterybulkassignlist";
const PICTURE_TASK_API = "/staff/getallpicturetasks";
const CONTEST_API = "/staff/getallcontests";
const MYSTERY_API = "/staff/mysteryshopperrecord";
const POSM_CONFIG = "/staff/posmconfiglist";
const POSM_AUDIT = "/staff/posmauditlist";
const POSM_AUDIT_LIST = "/staff/posmauditlist";
const COOLER_API = "/staff/getallcoolers";
const POSMENGAGEMENT_API = "/staff/getallposmengagement";
const REGION_LIST = '/region/get-region?program_id=1';
const STATE_LIST = '/state/get-state';
const CHANNEL_LIST = '/channels/get-channel?program_id=1';
const POSM_ELEMENTS = '/visibility/get-posm-elements?program_id=1';
const PICTURE = '/staff/get-engagement-transactions'

@Injectable({
  providedIn: "root",
})
export class TeamLeaderService {


  constructor(private http: HttpClient, private siteAuth: GlobalService) {}

  POSM_API = this.siteAuth.apiHostUrl + POSM_LIST;

  REGION   = this.siteAuth.apiHostUrl + REGION_LIST;

  STATE  = this.siteAuth.apiHostUrl + STATE_LIST;

  CHANNEL = this.siteAuth.apiHostUrl + CHANNEL_LIST;

  POSM_ELEMENTS = this.siteAuth.apiHostUrl + POSM_ELEMENTS;

  PICTURE_API = this.siteAuth.apiHostUrl + PICTURE;

  private getHeaders() {
    return new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("access-token"),
    });
  }

  public get(url: any): Observable<[]> {
    let headers = this.getHeaders();
    return this.http.get<any>(url, { headers: headers });
  }


  public getAPIHeaders(url: any): Observable<any> {
    return this.http.get<any>(url, { observe: "response" });
  }

  public getPostHeaders(url: any, data: any): Observable<any> {
    return this.http.post<any>(url, data, { observe: "response" });
  }

  PosmAuditTransactions(): Observable<PosmAuditTransactionsModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + LOGIN_API;
    return this.http
      .get<any>(apiUrl, { headers: headers })
      .pipe(
        map((response) => {
          return <PosmAuditTransactionsModel[]>response.data;
        }),
        catchError(this.handleError)
      );
  }

  coolerAuditTransactions(): Observable<PosmAuditTransactionsModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + COOLER_API;
    return this.http
      .get<any>(apiUrl, { headers: headers })
      .pipe(
        map((response) => {
          return <PosmAuditTransactionsModel[]>response.data;
        }),
        catchError(this.handleError)
      );
  }

  posmEngagementAuditTransactions(): Observable<PosmAuditTransactionsModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + POSMENGAGEMENT_API;
    return this.http
      .get<any>(apiUrl, { headers: headers })
      .pipe(
        map((response) => {
          return <PosmAuditTransactionsModel[]>response.data;
        }),
        catchError(this.handleError)
      );
  }

  PosmBulkAssignedRecords(): Observable<AssignedUsersCount[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + POSM_ASSIGNED_API;
    return this.http
      .get<any>(apiUrl, { headers: headers })
      .pipe(
        map((response) => {
          return <AssignedUsersCount[]>response.data;
        }),
        catchError(this.handleError)
      );
  }

  PictureBulkAssignedRecords(): Observable<AssignedUsersCount[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + PICTURE_ASSIGNED_API;
    return this.http
      .get<any>(apiUrl, { headers: headers })
      .pipe(
        map((response) => {
          return <AssignedUsersCount[]>response.data;
        }),
        catchError(this.handleError)
      );
  }

  posmAuditType(): Observable<PosmAuditModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + POSM_AUDIT_LIST;
    return this.http
      .get<any>(apiUrl, { headers: headers })
      .pipe(
        map((response) => {
          return <PosmAuditModel[]>response.data;
        }),
        catchError(this.handleError)
      );
  }

  PosmcoolerBulkAssignedRecords(): Observable<AssignedUsersCount[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + COOLER_ASSIGNED_API;
    return this.http
      .get<any>(apiUrl, { headers: headers })
      .pipe(
        map((response) => {
          return <AssignedUsersCount[]>response.data;
        }),
        catchError(this.handleError)
      );
  }

  PosmEngagementBulkAssignedRecords(): Observable<AssignedUsersCount[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + POSM_ENGAGEMENT_ASSIGNED_API;
    return this.http
      .get<any>(apiUrl, { headers: headers })
      .pipe(
        map((response) => {
          return <AssignedUsersCount[]>response.data;
        }),
        catchError(this.handleError)
      );
  }

  contestBulkAssignedRecords(): Observable<AssignedUsersCount[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + CONTEST_ASSIGNED_API;
    return this.http
      .get<any>(apiUrl, { headers: headers })
      .pipe(
        map((response) => {
          return <AssignedUsersCount[]>response.data;
        }),
        catchError(this.handleError)
      );
  }

  MysteryBulkAssignedRecords(): Observable<AssignedUsersCount[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + MYSTERY_ASSIGNED_API;
    return this.http
      .get<any>(apiUrl, { headers: headers })
      .pipe(
        map((response) => {
          return <AssignedUsersCount[]>response.data;
        }),
        catchError(this.handleError)
      );
  }

  TaskDetailsRecords(): Observable<PictureTaskModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + PICTURE_TASK_API;
    return this.http
      .get<any>(apiUrl, { headers: headers })
      .pipe(
        map((response) => {
          return <PictureTaskModel[]>response.data;
        }),
        catchError(this.handleError)
      );
  }

  // GET /v1/admin
  getAllPrograms(): Observable<ProgramModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + PROGAM_API;
    return this.http.get(apiUrl, { headers: headers }).pipe(
      map((response) => {
        return <any>response;
      }),
      catchError(this.handleError)
    );
  }

  GetCampaigns(): Observable<CampaignModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + CAMPAIGN_API;
    return this.http.get(apiUrl, { headers: headers }).pipe(
      map((response) => {
        return <any>response;
      }),
      catchError(this.handleError)
    );
  }

  BulkAssignedRecords(
    bulkassignvalue,
    category
  ): Observable<PosmAuditTransactionsModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + BULK_ASSIGNED_API;
    let body = JSON.stringify({
      category: category,
      bulkassignvalue: bulkassignvalue,
    });
    return this.http
      .post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map((response) => {
          return <PosmAuditTransactionsModel[]>response.data;
        }),
        catchError(this.handleError)
      );
  }

  ReAssignedRecords(
    ReassignedRecords,
    ReassignedUser,
    category
  ): Observable<PosmAuditTransactionsModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + REASSIGNED_API;
    let body = JSON.stringify({
      user_id: ReassignedUser,
      records: ReassignedRecords,
      category: category,
    });
    return this.http
      .post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map((response) => {
          return <PosmAuditTransactionsModel[]>response.data;
        }),
        catchError(this.handleError)
      );
  }

  // Contest Realted API
  getImageContestData(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + CONTEST_API;
    return this.http
      .get<any>(apiUrl, { headers: headers })
      .pipe(
        map((response) => {
          return <WeekEnttries[]>response.data;
        }),
        catchError(this.handleError)
      );
  }

  // Contest Realted API
  getInvoiceRecordsData(): Observable<any> {
    // console.log(WeekEnttries);
    return of(INOVICE);
  }

  // Mystery Shopper Records;

  getMysteryShopperRecords(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + MYSTERY_API;
    return this.http
      .get<any>(apiUrl, { headers: headers })
      .pipe(
        map((response) => {
          return <MysteryShopperRecord[]>response.data;
        }),
        catchError(this.handleError)
      );
  }

  PosmConfigList(): Observable<PosmConfigModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + POSM_CONFIG;
    return this.http
      .get<any>(apiUrl, { headers: headers })
      .pipe(
        map((response) => {
          return <PosmConfigModel[]>response.data;
        }),
        catchError(this.handleError)
      );
  }

  PosmAuditList(): Observable<PosmAuditModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + POSM_AUDIT;
    return this.http
      .get<any>(apiUrl, { headers: headers })
      .pipe(
        map((response) => {
          return <PosmAuditModel[]>response.data;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(response: any) {
    let errorMessage: any = {};
    // Connection error
    console.log(response);
    if (response.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: "Sorry, there was a connection error occurred. Please try again.",
      };
    } else {
      errorMessage = response.error;
    }
    return throwError(errorMessage);
  }
}
