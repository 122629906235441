import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
let proxy = "https://cors-anywhere.herokuapp.com/";

@Injectable({
  providedIn: 'root'
})

export class GlobalService {
  public apiHostUrl;
  apiHost: string;
  constructor() {
    if (environment.production == true) {
      //this.apiHostUrl = 'http://localhost/budhubadminapi/web/v1';
      //this.apiHostUrl = 'http://localhost/kingservice/api/web/v1';
      // this.apiHostUrl = 'https://api3.staging4.bigcityvoucher.co.in/v1';
      // this.apiHostUrl = 'http://api.staging11.bigcityvoucher.co.in/v1';
      //this.apiHostUrl =  'https://api1.bud4trade.bigcityexperiences.com/v1';
      // stagging sprit
      this.apiHostUrl = 'https://hubadminapi.budspirits.bigcityvoucher.co.in/v1'
    } else {
      //this.apiHostUrl = 'http://localhost/budhubadminapi/web/v1';
      //this.apiHostUrl = 'http://localhost/kingservice/api/web/v1';
      // this.apiHostUrl = 'https://api3.staging4.bigcityvoucher.co.in/v1';
      // this.apiHostUrl = 'http://api.staging11.bigcityvoucher.co.in/v1';
      //this.apiHostUrl =  'https://api1.bud4trade.bigcityexperiences.com/v1';
      // stagging sprit
      this.apiHostUrl = 'https://hubadminapi.budspirits.bigcityvoucher.co.in/v1'
    }
  }

}
