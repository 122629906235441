import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  constructor(private datePipe: DatePipe) { }

  pipe = new DatePipe('en-US');

  DownloadReportExcel(excelData) {
    let header = [
      'Outlet Name',
      'Outlet Code',
      'POSM Type',
      'POSM Audit Type',
      'Uploaded Image',
      'ML Output Image',
      'Channel',
      'Latitude',
      'Longtitude',
      'Points',
      'Correctly Interpreted by ML ?',
      'Purity Score',
      'Evaluated Purity Score',
      'Uploaded Date',
      'Assigned Date',
      'Assigned User Name',
      'Agent Comments',
      'Agent Comments Date',
      'Status',
      'Reason (IF Rejected)'
    ];

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('POSM Report', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
     //Add Header Row
    let headerRow = worksheet.addRow(header);
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0000'},
          bgColor: { argb: 'FF0000FF'}
        }
        cell.font = {
          bold: true,
          name: 'Arial',
          size: 12,
          color: { argb: 'FFFFFF'}
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
        cell.border = { top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' } }
        }
    );

    worksheet.columns = [
      {header:'Outlet Name',  key:'user_detail_name'},
      {header:'Outlet Code',  key:'ro_code'},
      {header:'POSM Type', key:'posm_type_name'},
      {header:'POSM Audit Type', key:'posm_audit_type_name'},
      {header:'Uploaded Image',  key:'image_url', width: 100},
      {header:'ML Output Image',  key:'posm_ml_output_image', width: 100},
      {header:'Channel',  key:'channel_desc'},
      {header:'Latitude',  key:'lat'},
      {header:'Longtitude', key:'lng'},
      {header:'Points',  key:'points'},
      {header:'Correctly Interpreted by ML ?',  key:'mlStatusString'},
      {header:'Purity Score',  key:'purity_score'},
      {header:'Evaluated Purity Score',  key:'evaluated_purity_score'},
      {header:'Uploaded Date', key:'updated_date'},
      {header:'Assigned Date',  key:'assigned_date'},
      {header:'Assigned User Name',  key:'assigned_user_name'},
      {header:'Agent Comments', key:'agent_comments'},
      {header:'Agent Comments Date',  key:'agent_comments_date'},
      {header:'Status', key:'statusString'},
      {header:'Reason (IF Rejected)',  key:'rejectedReasonString'}
    ]

    if(excelData.length > 0) {
        excelData.forEach(d => {
            let row = worksheet.addRow(d);
              row.font = {
                name: 'Arial',
                size: 12,
              },
              row.alignment = {
                vertical: 'middle', horizontal: 'center'
              };

              row.getCell(5).value = {
                text: d.image_url,
                hyperlink: d.image_url,
              };

              row.getCell(5).font = {
                color: { argb: '0066CC' },
                name: 'Arial',
                size: 12
              };
              row.getCell(6).value = {
                text: d.image_url,
                hyperlink: d.image_url
              };
              row.getCell(6).font = {
                color: { argb: '0066CC' },
                name: 'Arial',
                size: 12
              };
              row.getCell(14).value = this.datePipe.transform(row.getCell(14).value, 'fullDate')
              row.getCell(15).value = this.datePipe.transform(row.getCell(15).value, 'fullDate')
              row.getCell(18).value = this.datePipe.transform(row.getCell(18).value, 'fullDate')
            }
          );
    }

    worksheet.columns.forEach(function (column, i) {
        column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'ABINBEV_TOTAL_POSM_REPORT-'+this.datePipe.transform(new Date(), 'medium')+'.xlsx');
    })
  }

  DownloadEngagementReportExcel(excelData) {
    let header = [
      'Engagement Name',
      'Outlet Name',
      'Outlet Code',
      'POSM Type',
      'POSM Audit Type',
      'Uploaded Image',
      'ML Output Image',
      'Channel',
      'Latitude',
      'Longtitude',
      'Points',
      'Correctly Interpreted by ML ?',
      'Purity Score',
      'Evaluated Purity Score',
      'Uploaded Date',
      'Assigned Date',
      'Assigned User Name',
      'Agent Comments',
      'Agent Comments Date',
      'Status',
      'Reason (IF Rejected)'
    ];

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('POSM Report', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
     //Add Header Row
    let headerRow = worksheet.addRow(header);
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0000'},
          bgColor: { argb: 'FF0000FF'}
        }
        cell.font = {
          bold: true,
          name: 'Arial',
          size: 12,
          color: { argb: 'FFFFFF'}
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
        cell.border = { top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' } }
        }
    );

    worksheet.columns = [
      {header:'Engagement Name',  key:'title'},
      {header:'Outlet Name',  key:'user_detail_name'},
      {header:'Outlet Code',  key:'ro_code'},
      {header:'POSM Type', key:'posm_type_name'},
      {header:'POSM Audit Type', key:'posm_audit_type_name'},
      {header:'Uploaded Image',  key:'image_url', width: 100},
      {header:'ML Output Image',  key:'posm_ml_output_image', width: 100},
      {header:'Channel',  key:'channel_desc'},
      {header:'Latitude',  key:'lat'},
      {header:'Longtitude', key:'lng'},
      {header:'Points',  key:'points'},
      {header:'Correctly Interpreted by ML ?',  key:'mlStatusString'},
      {header:'Purity Score',  key:'purity_score'},
      {header:'Evaluated Purity Score',  key:'evaluated_purity_score'},
      {header:'Uploaded Date', key:'updated_date'},
      {header:'Assigned Date',  key:'assigned_date'},
      {header:'Assigned User Name',  key:'assigned_user_name'},
      {header:'Agent Comments', key:'agent_comments'},
      {header:'Agent Comments Date',  key:'agent_comments_date'},
      {header:'Status', key:'statusString'},
      {header:'Reason (IF Rejected)',  key:'rejectedReasonString'}
    ]

    if(excelData.length > 0) {
        excelData.forEach(d => {
            let row = worksheet.addRow(d);
              row.font = {
                name: 'Arial',
                size: 12,
              },
              row.alignment = {
                vertical: 'middle', horizontal: 'center'
              };

              row.getCell(6).value = {
                text: d.image_url,
                hyperlink: d.image_url,
              };

              row.getCell(6).font = {
                color: { argb: '0066CC' },
                name: 'Arial',
                size: 12
              };
              row.getCell(7).value = {
                text: d.image_url,
                hyperlink: d.image_url
              };
              row.getCell(7).font = {
                color: { argb: '0066CC' },
                name: 'Arial',
                size: 12
              };
              row.getCell(15).value = this.datePipe.transform(row.getCell(15).value, 'fullDate')
              row.getCell(16).value = this.datePipe.transform(row.getCell(16).value, 'fullDate')
              row.getCell(19).value = this.datePipe.transform(row.getCell(19).value, 'fullDate')
            }
          );
    }

    worksheet.columns.forEach(function (column, i) {
        column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'ABINBEV_TOTAL_ENAGEMENT_POSM_REPORT-'+this.datePipe.transform(new Date(), 'medium')+'.xlsx');
    })
  }

  DownloadCoolerReportExcel(excelData) {
    let header = [
      'Outlet Name',
      'Outlet Code',
      'POSM Type',
      'POSM Audit Type',
      'Uploaded Image',
      'ML Output Image',
      'Channel',
      'Latitude',
      'Longtitude',
      'Points',
      'Correctly Interpreted by ML ?',
      'Purity Score',
      'Evaluated Purity Score',
      'Uploaded Date',
      'Assigned Date',
      'Assigned User Name',
      'Agent Comments',
      'Agent Comments Date',
      'Status',
      'Reason (IF Rejected)'
    ];

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('POSM Report', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
     //Add Header Row
    let headerRow = worksheet.addRow(header);
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0000'},
          bgColor: { argb: 'FF0000FF'}
        }
        cell.font = {
          bold: true,
          name: 'Arial',
          size: 12,
          color: { argb: 'FFFFFF'}
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
        cell.border = { top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' } }
        }
    );

    worksheet.columns = [
      {header:'Outlet Name',  key:'user_detail_name'},
      {header:'Outlet Code',  key:'ro_code'},
      {header:'POSM Type', key:'posm_type_name'},
      {header:'POSM Audit Type', key:'posm_audit_type_name'},
      {header:'Uploaded Image',  key:'numerator_image', width: 100},
      {header:'ML Output Image',  key:'posm_ml_output_image', width: 100},
      {header:'Channel',  key:'channel_desc'},
      {header:'Latitude',  key:'lat'},
      {header:'Longtitude', key:'lng'},
      {header:'Points',  key:'points'},
      {header:'Correctly Interpreted by ML ?',  key:'mlStatusString'},
      {header:'Purity Score',  key:'purity_score'},
      {header:'Evaluated Purity Score',  key:'evaluated_purity_score'},
      {header:'Uploaded Date', key:'updated_date'},
      {header:'Assigned Date',  key:'assigned_date'},
      {header:'Assigned User Name',  key:'assigned_user_name'},
      {header:'Agent Comments', key:'agent_comments'},
      {header:'Agent Comments Date',  key:'agent_comments_date'},
      {header:'Status', key:'statusString'},
      {header:'Reason (IF Rejected)',  key:'rejectedReasonString'}
    ]

    if(excelData.length > 0) {
        excelData.forEach(d => {
            let row = worksheet.addRow(d);
              row.font = {
                name: 'Arial',
                size: 12,
              },
              row.alignment = {
                vertical: 'middle', horizontal: 'center'
              };

              row.getCell(5).value = {
                text: d.image_url,
                hyperlink: d.image_url,
              };

              row.getCell(5).font = {
                color: { argb: '0066CC' },
                name: 'Arial',
                size: 12
              };
              row.getCell(6).value = {
                text: d.image_url,
                hyperlink: d.image_url
              };
              row.getCell(6).font = {
                color: { argb: '0066CC' },
                name: 'Arial',
                size: 12
              };
              row.getCell(14).value = this.datePipe.transform(row.getCell(14).value, 'fullDate')
              row.getCell(15).value = this.datePipe.transform(row.getCell(15).value, 'fullDate')
              row.getCell(18).value = this.datePipe.transform(row.getCell(18).value, 'fullDate')
            }
          );
    }

    worksheet.columns.forEach(function (column, i) {
        column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'ABINBEV_TOTAL_COOLER_REPORT-'+this.datePipe.transform(new Date(), 'medium')+'.xlsx');
    })
  }

  DownloadCampaignEnagagementReportExcel(excelData) {
    debugger
    let header = [
      'Campaign Name',
      'Outlet Name',
      'Outlet Code',
      'Engagement Name',
      'Engagement Title',
      'Image',
      'Points',
      'Uploaded Date',
      'Assigned Date',
      'Assigned User Name',
      'Agent Comments',
      'Agent Comments Date',
      'Status',
      'Reason (IF Rejected)'
    ];

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Enagagement Report', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: 'portrait',
        margins: {
          left: 0.3149606, right: 0.3149606,
          top: 0.3543307, bottom: 0.3543307,
          header: 0.3149606, footer: 0.3149606
        }
      }
    });
     //Add Header Row
    let headerRow = worksheet.addRow(header);
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0000'},
          bgColor: { argb: 'FF0000FF'}
        }
        cell.font = {
          bold: true,
          name: 'Arial',
          size: 12,
          color: { argb: 'FFFFFF'}
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
        cell.border = { top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' } }
        }
    );

    worksheet.columns = [
      {header: 'Campaign Name', key: 'campaign_name'},
      {header:'Outlet Name',  key:'user_name'},
      {header:'Outlet Code',  key:'ro_code'},
      {header:'Engagement Name', key:'engagment_name', width: 500},
      {header:'Engagement Title',  key:'title', width: 500},
      {header:'Image',  key:'image_url', width: 500},
      {header:'Points',  key:'success_points'},
      {header:'Uploaded Date', key:'updated_date'},
      {header:'Assigned Date',  key:'assigned_date'},
      {header:'Assigned User Name',  key:'assigned_user_name'},
      {header:'Agent Comments', key:'agent_comments'},
      {header:'Agent Comments Date',  key:'agent_comments_date'},
      {header:'Status', key:'statusString'},
      {header:'Reason (IF Rejected)',  key:'rejectedReasonString'}
    ]

    if(excelData.length > 0) {
        excelData.forEach(d => {
            let row = worksheet.addRow(d);
              row.font = {
                name: 'Arial',
                size: 12,
              },
              row.alignment = {
                vertical: 'middle', horizontal: 'center'
              };

              row.getCell(6).value = {
                text: d.image_url,
                hyperlink: d.image_url,
              };

              row.getCell(6).font = {
                color: { argb: '0066CC' },
                name: 'Arial',
                size: 12
              };

              row.getCell(8).value = this.datePipe.transform(row.getCell(8).value, 'fullDate')
              row.getCell(9).value = this.datePipe.transform(row.getCell(9).value, 'fullDate')
              row.getCell(12).value = this.datePipe.transform(row.getCell(12).value, 'fullDate')
            }
          );
    }

    worksheet.columns.forEach(function (column, i) {
        column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'ABINBEV_TOTAL_Engagement_REPORT-'+this.datePipe.transform(new Date(), 'medium')+'.xlsx');
    })
  }
}
