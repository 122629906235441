import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GlobalService } from '../../Service/SiteAuth/global.service';
import { PosmAuditTransactionsModel } from '../../team-leader/Models/PosmAuditTransactionsModel';
import { PosmConfigModel, OrderOfferCode, PosmAuditModel } from '../Models/posm-config';
import { WEEKTASK, REWARDENTRIES, RATING, CITY, POSITION, WEEK, CATEGORY } from '../Models/mock-entries';
import { INOVICE } from '../../team-leader/Models/mock-entries';
import { CampaignModel } from '../../models/ProgramModel';
import { WeekEnttries, RatingModel } from '../Models/WeekEntries';
import { MysteryShopperRecord } from '../../team-leader/Models/MysteryShopper';
import { PictureTaskModel } from '../../team-leader/Models/WeekEntries';

//Related API's

const POSM_LIST = '/staff/get-assigned-posm-transactions';
const PICTURE_LIST = '/staff/agentpictureassignedlist';
const MYSTERY_LIST = '/staff/agentmysteryassignedlist';
const CONTEST_LIST = '/staff/agentcontestassignedlist';
const POSM_CONFIG = '/staff/posmconfiglist';
const POSM_AUDIT_LIST = '/staff/posmauditlist';
const POSM_APPROVE = '/staff/approveposm';
const MYSTERY_APPROVE = '/staff/approvemystery';
const PICTURE_APPROVE = '/staff/approvepicture';
const PICTURE_TEMPLATE = '/staff/get-picture-templates';
const POSM_REJECT = '/staff/rejectposm';
const PICTURE_REJECT = '/staff/rejectpicture';
const CAMPAIGN_API = '/admin/campaigns';
const CONTEST_RATING = '/staff/ratingparameters';
const CONTEST_UPDATE = '/staff/updateentry';
const GET_ALL_ROLES = '/staff/getuserroles';
const GETUSERDATA = '/staff/getuserdata';
const OFFERCODE_LIST = '/staff/offercodesummary';
const RESEND_CODE = '/staff/resendoffercode';
// Call Center
const CALL_PICTURE = '/staff/picturerecord';
const CALL_ENG_POSM = '/staff/engagementposm';
const CALL_POSM = '/staff/posmrecords';
const CALL_COOLER = '/staff/coolerrecords';
const PURITY_SELECTION = '/staff/get-chiller-pureimpure-selection-user-date';
const PICTURE_API = '/staff/get-engagement-transactions';
const AjentRejectedReason = "/staff/get-all-rejection";


@Injectable({
  providedIn: 'root'
})


export class AgentService {

  constructor(private http: HttpClient, private siteAuth: GlobalService) {
  }

  POSM_API = this.siteAuth.apiHostUrl + POSM_LIST;

  PURITY_SECTION = this.siteAuth.apiHostUrl + PURITY_SELECTION;

  PICTURE_API = this.siteAuth.apiHostUrl + PICTURE_API;

  PICTURE_TEMPLATE = this.siteAuth.apiHostUrl + PICTURE_TEMPLATE;

  Reason = this.siteAuth.apiHostUrl + AjentRejectedReason;

  private getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    });
  }

  public getAPIHeaders(url: any): Observable<any> {
    return this.http.get<any>(url, { observe: "response" });
  }

  public getPostHeaders(url: any, data: any): Observable<any> {
    return this.http.post<any>(url, data, { observe: "response" });
  }

  GetCampaigns(): Observable<CampaignModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + CAMPAIGN_API;
    return this.http.get(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <any>response;
          }
        ),
        catchError(this.handleError)
      )
  }


  AgentPosmAuditTransactions(): Observable<PosmAuditTransactionsModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + POSM_LIST;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <PosmAuditTransactionsModel[]>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  OfferCodeTransactions(id): Observable<OrderOfferCode[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + OFFERCODE_LIST + '?order_detail_id=' + id;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <OrderOfferCode[]>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }
  ResendOfferCode(user_id, offerid): Observable<OrderOfferCode[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + RESEND_CODE;
    let body = JSON.stringify({
      'user_id': user_id,
      'offer_code_id': offerid
    });
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return <OrderOfferCode[]>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  customerTransactions(): Observable<PosmAuditTransactionsModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + POSM_LIST;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <PosmAuditTransactionsModel[]>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  AgentPictureAuditTransactions(): Observable<PictureTaskModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + PICTURE_LIST;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <PictureTaskModel[]>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  AgentMysteryAuditTransactions(): Observable<MysteryShopperRecord[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + MYSTERY_LIST;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <MysteryShopperRecord[]>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  PosmConfigList(): Observable<PosmConfigModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + POSM_CONFIG;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <PosmConfigModel[]>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  PosmApprove(Posm: PosmAuditTransactionsModel): Observable<PosmAuditTransactionsModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + POSM_APPROVE;
    let body = Posm;
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return <PosmAuditTransactionsModel[]>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  MysteryApprove(Mystery: MysteryShopperRecord): Observable<MysteryShopperRecord[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + MYSTERY_APPROVE;
    let body = Mystery;
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return <MysteryShopperRecord[]>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  pictureApprove(Mystery: PictureTaskModel): Observable<PictureTaskModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + PICTURE_APPROVE;
    let body = Mystery;
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return <PictureTaskModel[]>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  pictureTempalte(): Observable<PictureTaskModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + PICTURE_TEMPLATE;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <PictureTaskModel[]>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  posmAuditType(): Observable<PosmAuditModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + POSM_AUDIT_LIST;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <PosmAuditModel[]>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  PosmReject(Posm: PosmAuditTransactionsModel): Observable<PosmAuditTransactionsModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + POSM_REJECT;
    let body = Posm;
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return <PosmAuditTransactionsModel[]>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  MysteryReject(Mystery: MysteryShopperRecord): Observable<MysteryShopperRecord[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + POSM_REJECT;
    let body = Mystery;
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return <MysteryShopperRecord[]>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  pictureReject(Mystery: PictureTaskModel): Observable<PictureTaskModel[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + PICTURE_REJECT;
    let body = Mystery;
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return <PictureTaskModel[]>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  // Contest Realted API
  getWeekEntriesData(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + CONTEST_LIST;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <WeekEnttries[]>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  updateWeekEntry(entry: WeekEnttries): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + CONTEST_UPDATE;
    let body = JSON.stringify(entry);
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getInvoiceRecordsData(): Observable<any> {
    return of(INOVICE)
  }

  // updateWeekEntry(entry: WeekEnttries): Observable<any> {
  //   let headers = this.getHeaders();
  //   let apiUrl = this.siteAuth.apiHostUrl + updateWinnerEntry;
  //   let body = JSON.stringify(entry);
  //   return this.http.post<any>(apiUrl, body, { headers: headers })
  //     .pipe(
  //       map(
  //         response => {
  //           return response;
  //         }
  //       ),
  //       catchError(this.handleError)
  //     )
  // }

  getTaskEntriesData(): Observable<any> {
    return of(WEEKTASK);
  }

  getCityData(): Observable<any> {
    return of(CITY)

  }

  getRatingEntries(): Observable<any> {
    return of(RATING)
  }

  getPositionData(): Observable<any> {
    return of(POSITION)
  }

  getWeeks(): Observable<any> {
    return of(WEEK)
  }

  getCategory(): Observable<any> {
    return of(CATEGORY)
  }

  getRatingParameter(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + CONTEST_RATING;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <RatingModel[]>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  getUserRoles(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + GET_ALL_ROLES;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <any>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  getuserDetails(roleid: number, mobile: string, outletcode: string): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + GETUSERDATA + '?mobile=' + mobile + '&roleid=' + roleid + '&outletcode=' + outletcode;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <any>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  // Call Center

  getPosmRecords(startdate: string, enddate: string, mobile: string, outletcode: string): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + GETUSERDATA + '?mobile=' + mobile + '&startdate=' + startdate + '&enddate=' + enddate + '&outletcode=' + outletcode;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <any>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }


  getPictureRecords(startdate: string, enddate: string, mobile: string, outletcode: string): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + CALL_PICTURE + '?mobile=' + mobile + '&startdate=' + startdate + '&enddate=' + enddate + '&outletcode=' + outletcode;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <any>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  getEngagementPosmRecords(startdate: string, enddate: string, mobile: string, outletcode: string): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + CALL_ENG_POSM + '?mobile=' + mobile + '&startdate=' + startdate + '&enddate=' + enddate + '&outletcode=' + outletcode;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <any>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  getCoolerRecords(startdate: string, enddate: string, mobile: string, outletcode: string): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + CALL_COOLER + '?mobile=' + mobile + '&startdate=' + startdate + '&enddate=' + enddate + '&outletcode=' + outletcode;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <any>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  getPOSMRecords(startdate: string, enddate: string, mobile: string, outletcode: string): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + CALL_POSM + '?mobile=' + mobile + '&startdate=' + startdate + '&enddate=' + enddate + '&outletcode=' + outletcode;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return <any>response.data;
          }
        ),
        catchError(this.handleError)
      )
  }


  private handleError(response: any) {
    let errorMessage: any = {};
    // Connection error
    console.log(response);
    if (response.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: 'Sorry, there was a connection error occurred. Please try again.'
      };
    } else {
      errorMessage = response.error;
    }
    return throwError(errorMessage);
  }
}
