import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import {Location} from '@angular/common';

declare var $;
@Component({
  selector: 'app-team-leader',
  templateUrl: './team-leader.component.html',
  styleUrls: ['./team-leader.component.scss'],
})
export class TeamLeaderComponent implements OnInit {

  constructor(private _location: Location,  private zone: NgZone,
    private changeDetector: ChangeDetectorRef) {
      setTimeout(() => {
        this.zone.run(() => {
          this.changeDetector.detectChanges();
        });
      });
  }

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
    $('body').addClass('hold-transition skin-blue sidebar-mini');
  }

  backClicked(){
    this._location.back();
  }

}
