import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgentsRoutingModule } from './agents-call-center-routing.module';
import { LayoutModule } from '../layout/layout.module';
import { AgentsCallCenterComponent } from './agents-call-center.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AlertService } from '../Providers/alertProvider';
import { AgentCallCenterProvider } from './Providers/AgentCallCenterProvider';
import { ProgramDataService } from '../Service/program-data.service';
import { ToastrModule } from 'ngx-toastr';
import { WeekEntriesProvider } from './Providers/WeekEntriesProvider';
import { AgentCallCenterDataProvider } from './Providers/AgentCallCenterDataProvider';
import { TeamLeaderProvider } from '../team-leader/Providers/TeamLeaderProvider';
import { AgentProvider } from '../agents/Providers/AgentProvider';
import { NotfoundComponent } from './Components/category/notfound/notfound.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';


@NgModule({
  declarations: [AgentsCallCenterComponent, NotfoundComponent],
  imports: [
    CommonModule,
    AgentsRoutingModule,
    LayoutModule,
    NgxUiLoaderModule,
    CarouselModule.forRoot(),
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true
    }),
  ],
  providers: [
    AgentCallCenterDataProvider,
    AlertService,
    AgentCallCenterProvider,
    ProgramDataService,
    WeekEntriesProvider,
    TeamLeaderProvider,
    AgentProvider
  ]
})
export class AgentsCallCenterModule { }
