import { Injectable } from '@angular/core';
import { AreaDataProvider } from '../../Providers/AreaDataProvider';
import { AgentCallCenterProvider } from './AgentCallCenterProvider';
import { RouteDataProvider } from '../../Shared/RouteDataProvider.provider';
import { EventType, EventData } from '../../models/sharedModel';
import { WeekEntriesProvider } from './WeekEntriesProvider';
import { TeamLeaderProvider } from '../../team-leader/Providers/TeamLeaderProvider';

@Injectable()

export class AgentCallCenterDataProvider extends AreaDataProvider {

    constructor(public routerDataProvider: RouteDataProvider,
        public agentProvider: TeamLeaderProvider,
        public agentProvider1: AgentCallCenterProvider,
        public weekEntriesProviders: WeekEntriesProvider
    ) {
        super('Entries', routerDataProvider)
    }

    areaChanged(newArea: string, firstCall: boolean) {
        if (firstCall) {
            return;
        }
    }

    subAreaChanged(newSubArea: string, firstCall: boolean) {
        if (firstCall || newSubArea == this.currentAreaName) {
            return;
        }
        this.eventHandler({ type: EventType.subAreaChanged });
    }


    subAreaInsideAreaChanged(newSubArea: string, firstCall: boolean) {
        if (firstCall || newSubArea == this.currentSubInsideAreaInsideName) {
            return;
        }
        this.eventHandler({ type: EventType.subAreaInsideAreaChanged });
    }

    queryParamChanged(newQueryParams: any, firstCall: boolean) {
        if (firstCall) {
            return;
        }
        if (newQueryParams.id !== undefined) {
            this.eventHandler({ type: EventType.queryParamChanged });
        } else {
            this.eventHandler({ type: EventType.areaChanged });
        }
    }

    subAreaInsideChanged(newSubArea: string, firstCall: boolean) {
        if (firstCall || newSubArea == this.currentAreaName) {
            return;
        }
        this.eventHandler({ type: EventType.subAreaInsideChanged });
    }

    urlMaker(data: any): string {
        return "";
    }

    eventHandler(eventData: EventData) {
        switch (eventData.type) {
            case EventType.queryParamChanged:
                console.log('Query Param Changed', this.currentQueryParam);
                break;
            case EventType.areaChanged:
                console.log('Query Param Changed', this.currentAreaName);
                this.agentProvider.getAllProgamData();
                this.agentProvider.getAllCampaignData();
                switch (this.currentAreaName) {
                    case "agent":
                        this.agentProvider.getAllPosmConfigList();
                        break;
                }
                break;
            case EventType.subAreaChanged:
                console.log('Sub Area Changed', this.currentSubAreaName);
                switch (this.currentSubAreaName) {
                    case "posm-tasks":
                        console.log('Posm Tasks Loaded');
                        this.agentProvider.getAllPosmAuditTransactionData();
                    case "cooler-tasks":
                        console.log('cooler Tasks Loaded');
                        this.agentProvider.getAllPosmAuditTransactionData();
                    case "engagement-posm-tasks":
                        console.log('cooler Tasks Loaded');
                        this.agentProvider.getAllPosmAuditTransactionData();
                    case "engagement-picture-tasks":
                        console.log('cooler Tasks Loaded');
                        this.agentProvider.getPictureTaskRecords();
                        this.agentProvider1.getTemplates();
                    case "contest-tasks":
                        console.log('Contest Task Loaded');
                        this.weekEntriesProviders.getWeekEntries();
                        this.weekEntriesProviders.getEntriesCategory();
                        this.weekEntriesProviders.getWinnersEntries();
                        this.weekEntriesProviders.getEntiesWeeks();
                        this.weekEntriesProviders.getEntiesCity();
                        this.weekEntriesProviders.getEntiesPosition();
                        this.weekEntriesProviders.getRatingOfEntries();
                        this.weekEntriesProviders.getRatingParameter();
                        break;
                    case "invoice-tasks":
                        this.agentProvider.getInvoicesRecords();
                        break;
                    case "mystery-tasks":
                        this.agentProvider.getMysteryShopperRecords();
                        break;
                    case "outlet-search":
                        //this.agentProvider.getUserDetailedDatas('', '', '');
                        break;
                }
                break;
            case EventType.subAreaInsideChanged:
                console.log('Sub Inside Area Changed', this.currentSubInsideAreaName);
                this.agentProvider.getAllPosmConfigList();
                switch (this.currentSubInsideAreaName) {

                }
                break;
        }
    }

}
